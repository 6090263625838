import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';
import { FaArrowRight } from 'react-icons/fa';
import MainOddsPopup from '../Modals/MainOddsPopup';
import ViewNewRequest from '../HospitalManagementViews/HospitalViewNewRequest';
import ViewInformation from '../HospitalManagementViews/HospitalViewInformation';

function TableListCredit({ hit, columnWidth }) {
  console.log(hit);
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  const endDate = new Date(hit?.EndDate * 1000)
    .toLocaleString()
    .split(',')[0];
  console.log(hit?.EndDate);
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15">{hit?.hospitalName}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15">{hit?.subscriptionId}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.planName?.[0]}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {/* <Moment format="DD/MM/YYYY" date={endDate} /> */}
            {endDate=='Invalid Date'?<p className='ms-5'>-</p>:endDate}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            {hit?.status}
          </div>
         
        </div>
      </div>
     
    </div>
  );
}

export default TableListCredit;
