import { useFormik } from 'formik';

import React, { useCallback, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { useDropzone } from 'react-dropzone';
import { createNewSubscriptionPlan, fileUploadApi } from '../../apis';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';

function ExistingPlanDetails({ closeFunction, data }) {
  console.log(data);
  return (
    <div className="bg-white rounded-2xl text-coalBlack py-5  overflow-x-hidden min-w-[560px]">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 py-6 border-b border-gray-100 ">
        <h1 className="headingText capitalize">{data?.planName}</h1>
        <button
          className="baseText !font-medium ms-auto text-navLink"
          onClick={() => closeFunction()}
        >
          Discard
        </button>
      </header>
      <div className=" p-7  space-y-4 max-w-xl">
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">Plan Icon</p>
          <div className="bg-white size-20 rounded-lg flex justify-center items-center">
            <img
              src={data?.planIcon}
              className="block rounded-lg object-contain m-auto"
            />
          </div>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">plan name</p>
          <p className="baseText text-coalBlack capitalize">{data?.planName}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">plan description</p>
          <p className="baseText text-coalBlack ">{data?.planDescription}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">plan Period</p>
          <p className="baseText text-coalBlack capitalize">{data?.period}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">plan Amount</p>
          <p className="baseText text-coalBlack ">{data?.planAmount}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">No of credits</p>
          <p className="baseText text-coalBlack ">{data?.noOfCredit}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">discount percentage</p>
          <p className="baseText text-coalBlack ">{data?.discountPercentage}</p>
        </div>
        <div className="singleField">
          <p className="subHeadingText mb-2 capitalize">discount description</p>
          <p className="baseText text-coalBlack ">{data?.discountDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default ExistingPlanDetails;
