import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import '../tableList.css';
import Moment from 'react-moment';
import { FaArrowRight } from 'react-icons/fa';
import MainOddsPopup from '../../Modals/MainOddsPopup';
import ViewInformation from '../../DoctorManagementViews/DoctorViewInformation';

function ReportPayoutList({hit, columnWidth}) {
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item' >
          
          <div style={{width: columnWidth}} className='text-coalBlack smallText cursor-pointer' onClick={handleViewNewrequest}>
            {hit?.Doctorname?.[0]}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
            {hit?.RequestAmount}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
           {hit?.AccountNumber?.[0]}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
           {hit?.TransactionMethod}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText '>
           {hit?.TransactionId}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
            <Moment format='DD/MM/YYYY' date={hit?.paidDate} />
          </div>
         
         
        </div>
      </div>
      {/* {
        viewRequest &&
         <MainOddsPopup closeFunction={handleViewNewrequest}>
         <ViewInformation id={hit._id} closeFunction={handleViewNewrequest} />
         </MainOddsPopup>
      } */}
    </div>
    
  );
}

export default ReportPayoutList;