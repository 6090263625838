import React,{useEffect, useState} from 'react';
import { updateNotificationReadApi } from '../../apis';
import { useNavigate } from 'react-router-dom';
function MessageCard({
  data,
  setcurrentrequest,
  notificationfun }) {

  useEffect(() => {
    setcurrentrequest(data?.ConsultationId);
    console.log(data?.ConsultationId);
  }, [data]);
  const navigate = useNavigate();
  const updateread=async()=>{
    try {
      const res = await updateNotificationReadApi({
        '_id': data?._id,
        'isRead': true
      });
      if (res.status === 200) {
        console.log(res.data);
        notificationfun();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const ReturnLogo = (type) => {  
    switch (type) {
      case "Hospital":
        return (
          <img src={require('../../images/HospitalSign.png')} className="size-10 min-w-10 rounded-full bg-gray-200 object-cover" />
        );
        break;
        case "Doctor":
        return (
          <img src={require('../../images/BearedGuy02-min1.png')} className="size-10 min-w-10 rounded-full bg-gray-200 object-cover" />
        );
        break;
        case "Payout":
        return (
          <img src={require('../../images/payoutIcon/vuesax/bulk/vuesax/bulk/direct-up.png')} className="size-10 min-w-10 rounded-full bg-gray-200 object-cover" />
        );
        break;
      default:
        return (
          <img src={require('../../images/noti.jpeg')} className="size-10 min-w-10 rounded-full bg-gray-200 object-cover" />
        );
    }

  }
  const callnavigate=(type)=>{
    if(data?.RequestId){
      navigate('/payoutmanagement');
    }
    else if(data?.doctorId){
      navigate('/doctormanagement');
    }
    else if(data?.hospitalId){
      navigate('/hospitalmanagement');
    }
    else{

    }
 
  }
  
  return (
    <div className="messageDiv p-3 border-b border-gray-200 bg-white cursor-pointer">
      <div className="flex gap-4 relative" onClick={()=>{updateread();callnavigate()}}>
      {
      data?.RequestId ? ReturnLogo('Payout') :
      data?.doctorId ? ReturnLogo('Doctor') :
      data?.hospitalId ? ReturnLogo('Hospital') :
       null}
        <div className="space-y-1">
          <p className="baseText text-coalBlack capitalize">{data?.message}</p>
        </div>
        {
          (!data?.isRead)  && <div className="w-3 h-3 rounded-full bg-blue-300 border-2 border-blue-600 absolute -top-2 -left-2"></div>
        }
        
      </div>
      
    </div>
    
  );
}

export default MessageCard;
