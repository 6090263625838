import React, { useRef, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { FaFilter } from 'react-icons/fa';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseHospitalList from '../../components/TypesenseList/TypesenseHospital';
function HospitalManagement() {
  
  const titleTab = {
    active: true,
    heading: 'Hospital management',
    btnBlock: false,
    btn: null,
    imgBlock: true,
    img: (
      <img
        src={require('../../images/HospitalSign.png')}
        className="object-cover rounded-full"
      />
    ),
  };
  const [step, setStep] = useState(1);
  const [sortOpt, setSortOpt] = useState('asc');
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-hospitalswithprofiles',
          sort_by: `RegisteredDate:${(sortOpt==='asc') ? 'desc':'asc'}`,
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchInputRef = useRef(null);
  const searchClient = typesenseInitiate();
  const [searchresult, setsearchresult] = useState(0);
  return (
    <AdminLayout titleTab={titleTab}>
      <div className="px-6">
        <div className="min-h-20 p-4 bg-white rounded-2xl capitalize flex items-center">
          <p className="headingText text-navyBlue ">
            {step === 1 ? 'New Registrations' : step === 2 ? 'Hospitals' : ''}
          </p>
          <div className="inline-flex items-center gap-5 ms-auto">
            <SimpleButton
              title={'New Registration'}
              customClass={'rounded-full !px-5'}
              buttonType={step === 1 ? 'primary' : 'primary-unfilled'}
              onClickEvent={() => {
                setStep(1);
              }}
            />

            <SimpleButton
              title={'Existing User'}
              customClass={'rounded-full !px-5 '}
              buttonType={step === 2 ? 'primary' : 'primary-unfilled'}
              onClickEvent={() => {
                setStep(2);
              }}
            />
          </div>
        </div>

        <section className=" bg-white rounded-2xl p-5 my-6 relative">
          <div className="flex items-center capitalize">
            <div className="capitalize ">
              <p className="baseText text-coalBlack ">List of Hospital</p>
              <p className="smallText text-navLink">
                {searchresult} available hospital
              </p>
            </div>
            <div className="ms-auto">
              <div className="inline-flex items-center gap-5">
                <div className="inline-block bg-accent rounded-lg p-2 shadow-blue_dropshadow relative cursor-pointer group/sort">
                  <FaFilter className="text-2xl text-white" />
                  <div className="sortIndex absolute top-full -left-20 bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible ">
                    <p
                      className={`${
                        sortOpt == 'asc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1 `}
                      onClick={() => {
                        setSortOpt('asc');
                      }}
                    >
                      Newest
                    </p>
                    <p
                      className={`${
                        sortOpt == 'desc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1`}
                      onClick={() => {
                        setSortOpt('desc');
                      }}
                    >
                      Oldest
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <TypesenseHospitalList
              searchClient={searchClient}
              step={step}
              searchInputRef={searchInputRef}
              setsearchresult={setsearchresult}
            />
          </div>
        </section>
      </div>
    </AdminLayout>
  );
}

export default HospitalManagement;
