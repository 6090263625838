import React, { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import "./tableList.css";
import Moment from "react-moment";
import { FaArrowRight } from "react-icons/fa";
import MainOddsPopup from "../Modals/MainOddsPopup";
import ViewNewRequest from "../HospitalManagementViews/HospitalViewNewRequest";
import ViewInformation from "../HospitalManagementViews/HospitalViewInformation";
import ViewPayoutRequest from "../payoutManagementViews/ViewPayoutRequest";
import ViewPayoutDetails from "../payoutManagementViews/ViewPayoutDetails";

function TableListPayout({ hit, columnWidth }) {
  console.log(hit);
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15"> {hit?.Doctorname?.[0]}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.PhoneNumber?.[0]}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.AccountNumber?.[0]}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.RequestAmount}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <Moment format="DD/MM/YYYY" date={hit?.RequestOn} />
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <a className="text-coalBlack" onClick={handleViewNewrequest}>
              View <FaArrowRight className="inline-block ms-1" />
            </a>
          </div>
        </div>
      </div>
      {viewRequest && (
        <MainOddsPopup
          closeFunction={() => {
            setViewRequest(false);
          }}
        >
          {hit?.status === "Requested" ? (
            <ViewPayoutRequest
              id={hit?._id}
              closeFunction={() => {
                setViewRequest(false);
              }}
            />
          ) : (
            <ViewPayoutDetails
              id={hit?._id}
              closeFunction={() => {
                setViewRequest(false);
              }}
            />
          )}
        </MainOddsPopup>
      )}
    </div>
  );
}

export default TableListPayout;
