import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ResponsiveTable from './ResponsiveTable';

function PaginatedTable({ items, itemsPerPage, tableHeaders }) {
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setCurrentPage(event.selected);
    setItemOffset(newOffset);
  };
  console.log(items);

  return (
    <>
      <div className="relative overflow-x-auto scrollbar">
        <ResponsiveTable headers={tableHeaders} data={currentItems} />
        {pageCount > 0 && (
          <section className="flex items-center mt-4 pb-1.5">
            <p className="smallText text-navLink capitalize">
              <span>showing page</span>{' '}
              <span className="min-w-4 text-center inline-block ">
                {currentPage + 1}
              </span>{' '}
              <span>of</span>{' '}
              <span className="min-w-4 text-center inline-block ">
                {pageCount}
              </span>{' '}
            </p>
            <div className="ms-auto">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
                containerClassName={'pagination'}
              />
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default PaginatedTable;
