import * as yup from 'yup';
import { isValidWebsiteLink } from '../../apis';
import { isValidPhoneNumber } from 'react-phone-number-input';

const URL = /^(?:(?:https?|http|www)\:\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*$/;

//  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
// /((https?):\/\/)/;

export const WltSignInValidation = yup.object({
  email: yup
    .string()
    .email('please enter a valid email')
    .required('This field can\'t be Empty'),
});
export const MarkAsPaidValidation = yup.object({
  transactiontype: yup.string().required('This field can\'t be Empty'),
  transactionid: yup.string().required('This field can\'t be Empty'),
});
// export const creditUpdateValidation = yup.object().shape({
//   inPersonCreditUpdate: yup
//     .number()
//     .typeError('Must be a number')
//     .integer('Must be an integer')
//     .min(1, 'Must be greater than zero')
//     .required('This field is required'),
//   virtualCreditUpdate: yup
//     .number()
//     .typeError('Must be a number')
//     .integer('Must be an integer')
//     .min(1, 'Must be greater than zero')
//     .required('This field is required'),
// });

export const creditUpdateValidation = yup.object().shape({
  inPersonCreditUpdate: yup
    .number()
    .typeError('Must be a number')
    .integer('Must be an integer')
    .min(10, 'Must be at least 10')
    .test(
      'is-multiple-of-10',
      'Must be a multiple of 10',
      (value) => value % 10 === 0
    )
    .required('This field is required'),
  virtualCreditUpdate: yup
    .number()
    .typeError('Must be a number')
    .integer('Must be an integer')
    .min(10, 'Must be at least 10')
    .test(
      'is-multiple-of-10',
      'Must be a multiple of 10',
      (value) => value % 10 === 0
    )
    .required('This field is required'),
});
export const createPlanValidation = yup.object().shape({
  planIcon: yup.string().required('Plan icon is required'),
  planName: yup.string().required('Plan name is required'),
  planDis: yup.string().required('Plan description is required'),
  discountPercentage: yup
    .number()
    .required('Discount percentage is required')
    .min(0, 'Discount percentage must be at least 0')
    .max(100, 'Discount percentage must be at most 100'),
  discountDis: yup.string().required('Discount description is required'),
  planAmount: yup
    .number()
    .required('Plan amount is required')
    .min(0, 'Plan amount must be at least 0'),

  planType: yup.string().required('Plan type is required'),
  // credits: yup
  // .number()
  // .required('Credits are required')
  // .min(0, 'Credits must be at least 0'),
});
export const payoutUpdateValidation = yup.object().shape({
  inPersonPayoutUpdate: yup
    .number()
    .typeError('Must be a number')
    .integer('Must be an integer')
    .min(1, 'Must be greater than zero')
    .required('This field is required'),
  virtualPayoutUpdate: yup
    .number()
    .typeError('Must be a number')
    .integer('Must be an integer')
    .min(1, 'Must be greater than zero')
    .required('This field is required'),
});

// sign-in, sign-up & forgot password validation start
export const SignupValidation = yup.object({
  userName: yup.string().required('This field can\'t be Empty'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  password: yup
    .string()
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
  isTermsAccepted: yup
    .bool()
    .oneOf([true], 'You must accept the terms and conditions.'),
});
export const SignInValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
  password: yup.string().required('Please enter a valid password'),
  keepMeLoggedIn: yup.bool(),
});
export const ForgotPasswordEmailValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
});
export const ResetPasswordValidation = yup.object({
  newpassword: yup
    .string()
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref('newpassword'), null], 'Passwords must match')
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
});
// sign-in, sign-up & forgot password validation end

// Doctor registration validation start
export const DoctorRegistrationStepOne = yup.object({
  docName: yup.string().required('This field can\'t be Empty'),
  birthDate: yup
    .date()
    .required('This field can\'t be Empty')
    .min(new Date(1900, 0, 1), 'Age must be after Jan 1st, 1900')
    .max(new Date(), 'Age cannot be in the future'),
  gender: yup
    .string()
    .required('Selecting a gender is required')
    .oneOf(['male', 'female', 'others'], 'Invalid gender selection'),
  phoneNo: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
  countryCode: yup.string(),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
});

export const DoctorRegistrationStepTwo = yup.object({
  specialization: yup.string().required('This field can\'t be Empty'),
  regNumber: yup.string().required('This field can\'t be Empty'),
  smCouncil: yup.string().required('This field can\'t be Empty'),
  dateOfReg: yup
    .date()
    .required('This field can\'t be Empty')
    .min(new Date(1900, 0, 1), 'Registration must be after Jan 1st, 1900')
    .max(new Date(), 'Registration cannot be in the future'),
});
export const DoctorRegistrationStepThree = yup.object({
  practiceHospital: yup.string(),
  HospitalNumber: yup
    .string()
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
});
export const DoctorRegistrationStepFour = yup.object({
  MedicalLicense: yup.mixed().required('This field can\'t be Empty'),
  DegreeCertificate: yup.mixed().required('This field can\'t be Empty'),
  refPhoneNo: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
  refContact: yup.string().required('This field can\'t be Empty'),
});
// Doctor registration validation END

export const updateUserValidation = yup.object({
  name: yup.string().required('This field can\'t be Empty'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
  image: yup.mixed(),
});

// edit user profile validation start
export const editUserProfile = yup.object({
  userEmail: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
  // dateOfBirth: yup.string().required('This field can\'t be empty'),
  // location: yup.string().required('This field can\'t be empty'),
});

export const deleteAcctPassword = yup.object({
  password: yup
    .string()
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
});
// edit user profile validation end
export const GetBankAccountDetails = yup.object({
  holderName: yup.string(),
  accountNumber: yup.string(),
  ifscCode: yup.string(),
});
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`  */

export const AccountDetailsValidation = yup.object({
  experience: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  workedForYourself: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  minDayRate: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  isTerms: yup
    .boolean()
    .oneOf([true], 'You cannot proceed without agreeing the terms'),
});
const __clientContacts = yup.object().shape({
  clientName: yup.string().required('This field can\'t be empty'),
  clientEmail: yup
    .string()
    .email('Please enter valid email')
    .required('This field can\'t be empty'),
  clientPhoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {
      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      } else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),
});

export const briefValidation = yup.object({
  clientName: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('please'),
        label: yup.string().required('please'),
      })
    )
    .nullable()
    .required('This Field Can\'t be empty'),
  briefName: yup.string().required('This field can\'t be empty'),
  logo: yup.mixed().required('This field can\'t be empty'),
  industry: yup.string().required('This field can\'t be empty'),
  region: yup.string().required('This field can\'t be empty'),
  clientType: yup.string().required('This field can\'t be empty'),
  category: yup.string().required('This field can\'t be empty'),
  engagementStructure: yup.string().required('This field can\'t be empty'),
});

export const briefValidation2 = yup.object({
  inBrief: yup.string(),
  background: yup.string(),
  projectAmbition: yup.string(),
  brand: yup.string(),
  experience: yup.string(),
  audience: yup.string(),
  kpis: yup.string(),
});

export const projectVal3 = yup.object({
  deliverableDetails: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This field can\'t be empty'),
      startDate: yup.string().required('This field can\'t be empty'),
      dueDate: yup.string().required('This field can\'t be empty'),
      masherRole: yup.object().required('This field can\'t be empty'),
      delieveryFormat: yup.string().required('This field can\'t be empty'),
      order: yup
        .number()
        .typeError('value should be number')
        .required('This field can\'t be empty')
        .test(
          'Is positive?',
          'Order must be greater than 0  and not repeated',
          (value, a) => {
            const isDuplicate = Boolean(
              a?.from?.[1]?.value?.deliverableDetails?.filter(
                (e) => e?.order === value
              )?.length > 1
            );
            return Boolean(value > 0) && !isDuplicate;
          }
        ),
      description: yup.string().required('This field can\'t be empty'),
    })
  ),
  budget: yup.number(),
  projectFee: yup.number(),
  projectCurrency: yup.string(),
  currency: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable(),
  websiteLink: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        link: yup
          .string()
          .matches(URL, 'Please enter correct url with http:// or https:// '),
      })
    )
    .nullable(),
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        file: yup.mixed(),
      })
    )
    .nullable(),
});

export const briefValidation3 = yup.object({
  deliverableDetails: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This field can\'t be empty'),
      startDate: yup.string().required('This field can\'t be empty'),
      dueDate: yup.string().required('This field can\'t be empty'),
      masherRole: yup.object().required('This field can\'t be empty'),
      delieveryFormat: yup.string().required('This field can\'t be empty'),
      order: yup
        .number()
        .typeError('value should be number')
        .required('This field can\'t be empty')
        .test(
          'Is positive?',
          'Order must be greater than 0  and not repeated',
          (value, a) => {
            const isDuplicate = Boolean(
              a?.from?.[1]?.value?.deliverableDetails?.filter(
                (e) => e?.order === value
              )?.length > 1
            );
            return Boolean(value > 0) && !isDuplicate;
          }
        ),
      description: yup.string().required('This field can\'t be empty'),
    })
  ),
  budget: yup.number(),
  currency: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable(),
  websiteLink: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        link: yup
          .string()
          .matches(URL, 'Please enter correct url with http:// or https:// '),
      })
    )
    .nullable(),
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        file: yup.mixed(),
      })
    )
    .nullable(),
});

export const editBriefValidation = yup.object({
  clientName: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('please'),
        label: yup.string().required('please'),
      })
    )
    .nullable(),
  briefName: yup.string().required('This field can\'t be empty'),
  logo: yup.mixed().required('This field can\'t be empty'),
  industry: yup.string().required('This field can\'t be empty'),
  region: yup.string().required('This field can\'t be empty'),
  clientType: yup.string().required('This field can\'t be empty'),
  category: yup.string().required('This field can\'t be empty'),
  engagementStructure: yup.string().required('This field can\'t be empty'),
});

export const editBriefValidation2 = yup.object({
  inBrief: yup.string(),
  background: yup.string(),
  projectAmbition: yup.string(),
  brand: yup.string(),
  experience: yup.string(),
  audience: yup.string(),
  kpis: yup.string(),
});

export const editBriefValidation3 = yup.object({
  deliverableDetails: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This field can\'t be empty'),
      startDate: yup.string().required('This field can\'t be empty'),
      dueDate: yup.string().required('This field can\'t be empty'),
      masherRole: yup.object().required('This field can\'t be empty'),
      delieveryFormat: yup.string().required('This field can\'t be empty'),
      description: yup.string().required('This field can\'t be empty'),
      order: yup
        .number()
        .typeError('value should be number')
        .required('This field can\'t be empty')
        .test(
          'Is positive?',
          'Order must be greater than 0  and not repeated',
          (value, a) => {
            const isDuplicate = Boolean(
              a?.from?.[1]?.value?.deliverableDetails?.filter(
                (e) => e?.order === value
              )?.length > 1
            );
            return Boolean(value > 0) && !isDuplicate;
          }
        ),
    })
  ),
  budget: yup.number(),
  currency: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable(),
  websiteLink: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        link: yup
          .string()
          .matches(URL, 'Please enter correct url with http:// or https:// '),
      })
    )
    .nullable(),
  attachement: yup.mixed(),
});
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~` */

export const convertToProjectValidation = yup.object({
  briefId: yup.string().required('This field can\'t be empty'),
  projectName: yup.string().required('This field can\'t be empty'),
  projectDescription: yup
    .string()
    .max(100, 'This can\'t be exceeded 100 characters'),
  clientName: yup.string().required('This field can\'t be empty'),
  deliverableDetails: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This field can\'t be empty'),
      dueDate: yup.string().required('This field can\'t be empty'),
      masherRole: yup.object().required('This field can\'t be empty'),
      order: yup
        .number()
        .typeError('value should be number')
        .required('This field can\'t be empty')
        .test(
          'Is positive?',
          'Order must be greater than 0  and not repeated',
          (value, a) => {
            const isDuplicate = Boolean(
              a?.from?.[1]?.value?.deliverableDetails?.filter(
                (e) => e?.order === value
              )?.length > 1
            );
            return Boolean(value > 0) && !isDuplicate;
          }
        ),
    })
  ),
  projectFee: yup.number().required('This field can\'t be empty'),
  masherFee: yup.number().required('This field can\'t be empty'),
  mashManager: yup.string().required('This field can\'t be empty'),
  additionalInfo: yup.string(),
  attachments: yup.mixed(),
});

export const editProjectValidation = yup.object({
  id: yup.string().required('This field can\'t be empty'),
  projectName: yup.string().required('This field can\'t be empty'),
  projectDescription: yup
    .string()
    .required('This field can\'t be empty')
    .max(100, 'This can\'t be exceeded 100 characters'),
  clientName: yup.string().required('This field can\'t be empty'),
  deliverableDetails: yup.array().of(
    yup.object().shape({
      name: yup.string().required('This field can\'t be empty'),
      dueDate: yup.string().required('This field can\'t be empty'),
      masherRole: yup.object().required('This field can\'t be empty'),
      order: yup
        .number()
        .typeError('value should be number')
        .required('This field can\'t be empty')
        .test(
          'Is positive?',
          'Order must be greater than 0  and not repeated',
          (value, a) => {
            const isDuplicate = Boolean(
              a?.from?.[1]?.value?.deliverableDetails?.filter(
                (e) => e?.order === value
              )?.length > 1
            );
            return Boolean(value > 0) && !isDuplicate;
          }
        ),
    })
  ),
  projectFee: yup.number().required('This field can\'t be empty'),
  masherFee: yup.number().required('This field can\'t be empty'),
  mashManager: yup.string().required('This field can\'t be empty'),
  additionalInfo: yup.string().required('This field can\'t be empty'),
  team: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required(),
  attachments: yup.mixed(),
});

export const inviteMasherValidation = yup.object({
  briefId: yup.string().required('This field can\'t be empty'),
  msg: yup.string().required('This field can\'t be empty'),
  mashers: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      email: yup.string(),
    })
  ),
  attachments: yup.mixed(),
});
export const referMashersValidation = yup.object({
  msg: yup.string().required('This field can\'t be empty'),
  mashers: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      email: yup.string(),
    })
  ),
  attachments: yup.mixed(),
});

export const addNoteValidation = yup.object({
  message: yup.string().required('This field can\'t be empty'),
});

export const addNoteForClientValidation = yup.object({
  notes: yup.string().required('This field can\'t be empty'),
});

export const InviteMasherValidation = yup.object({
  email: yup.string().required('This field can\'t be empty'),
  // msg: yup.string().required("This field can't be empty"),
  firstName: yup.string().required('This field can\'t be empty'),
  lastName: yup.string().required('This field can\'t be empty'),
});

export const newChatValidation = yup.object({
  message: yup.string(),
});

export const EditProfileValidation = yup.object({
  firstName: yup.string().required('This field is required.'),
  biography: yup.string(),
  email: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),

  skills: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'This can\'t be empty'),
  location: yup.string().required('This field is required'),
  brand: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    })
  ),
  lovedBrand: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    })
  ),
  otherLinks: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'This can\'t be empty'),
  language: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(''),
        label: yup.string().required(''),
      })
    )
    .min(1, 'Skills can\'t be empty')
    .required('Languages can\'t be empty'),
  phoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {
      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      } else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),
  cv: yup.mixed(),
  birthDate: yup.string().required('This field is required'),
  introVideo: yup.mixed(),
  portfolioVideo: yup.mixed(),
  instagram: yup.string(),
  facebook: yup
    .string()
    .matches(URL, 'Please enter correct url with http:// or https://'),
  twitter: yup
    .string()
    .matches(URL, 'Please enter correct url with http:// or https://'),
  linkedin: yup
    .string()
    .matches(URL, 'Please enter correct url with http:// or https://'),
  tiktok: yup
    .string()
    .matches(URL, 'Please enter correct url with http:// or https://'),
  websiteUrl: yup
    .string()
    .matches(URL, 'Please enter correct url with http:// or https://'),
});

export const createClientValidation = yup.object({
  brandName: yup.string().required('This field can\'t be empty'),
  logo: yup.mixed(),
  industry: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable()
    .required('This Field Can\'t be empty'),
  type: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable()
    .required('This Field Can\'t be empty'),
  region: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable()
    .required('This Field Can\'t be empty'),
  masherWorkWith: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    })
  ),

  clientContacts: yup.array().of(__clientContacts),
  clientName: yup.string().required('This field can\'t be empty'),
  // clientDesignation: yup.string().required("This field can't be empty"),
  coverImage: yup.string(),
  websiteLink: yup
    .string()
    .required('This field can\'t be empty')
    .test({
      message: () => 'Please enter valid url with http:// or https://',
      test: async (values) => {
        if (values) {
          try {
            const response = await isValidWebsiteLink(values);
            return response.data;
            // if (response.status === 200) {
            //   return true;
            // } else {
            //   return false;
            // }
          } catch (error) {
            console.log(error);
          }
        }
      },
    }),
  clientEmail: yup
    .string()
    .email('Please enter valid email')
    .required('This field can\'t be empty'),
  notes: yup.string().required('This field can\'t be empty'),
  clientPhoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {
      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      } else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),
});
