import React from 'react';
import './tableList.css';
function TableListHeader({headers, columnWidth,customClass}) {

  return (
    <div className='table-list-header-container bg-gray-50 '>
      {headers?.map((header, i) => {
        return <div className={`table-list-header-item  font-semibold  text-navLink uppercase ${customClass}`} key={i} style={{width: columnWidth, display: `${header.display}`, justifyContent: `${header.justify}`}}>{header.title}</div>;
      })}
    </div>
  );
}

export default TableListHeader;