import React, { useState, useEffect } from 'react';
import PaginatedTable from '../TableOdds/PaginatedTable';
import { Select } from 'react-select';
import { getDoctorById, getHospitalById } from '../../apis';
import Moment from 'react-moment';
import Noprofile from '../../Assets/no-image.png';
import { FaImage } from 'react-icons/fa';

function ViewInformation({ id, closeFunction }) {
  const [data, setdata] = useState(null);
  const [Loader, setLoader] = useState(false);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getDoctorById(id);
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, []);
  const TableHeaders = [
    'Hospital Name',
    'Consultation Number',
    'Appointment Type',
    'Appointment Date',
    'Priority',
    'payment',
  ];
  const date = new Date(data?.profile?.birthDate);
  const formattedDate = date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return (
    <div className="bg-white  rounded-2xl text-coalBlack pb-5 pt-0">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 py-4  border-b border-gray-100 mb-6">
        <h1 className="headingText capitalize">Doctor information</h1>
        <div className='ms-auto space-x-6'>
          <div className='inline-flex gap-x-2 px-6 border-r border-gray-200'>
            <p className="inline-block  capitalize baseText text-coalBlack">
            Reported No show
            </p>
            <span className='bg-red-500 text-white baseText md:!font-medium rounded-full p-1 size-6 flex justify-center items-center'>
              {data?.profile?.NoShowCount}
            </span>
          </div>
          <button
            className="capitalize text-base font-medium text-navLink ms-auto"
            onClick={() => {
              closeFunction();
            }}
          >
            close
          </button>
        </div>
      </header>
      <main className="space-y-10 px-4">
        <section className="shadow-blue_dropshadow2 p-4 rounded-[58px]">
          <div className=" flex flex-row gap-6 ">
            <div className="max-w-[190px] w-full">
              {Loader ? (
                <div
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex"
                >
                  <FaImage size={60} className="text-white" />
                </div>
              ) : data?.profile?.coverPic ? (
                <img
                  src={data?.profile.coverPic}
                  alt="Cover"
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover"
                />
              ) : (
                <img
                  src={Noprofile}
                  alt="No Profile"
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-contain"
                />
              )}
            </div>
            <section className="flex-1">
              <h1 className="subHeadingText capitalize mb-1.5 !font-semibold">
                personal details
              </h1>
              <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    Name
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.DoctorName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    Email
                  </p>
                  <p className="baseText  break-all text-coalBlack">
                    {data?.profile?.userId?.email}
                  </p>
                </div>

                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    phone number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode}{' '}
                    {data?.profile?.reference?.contactNo}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    DOB
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {formattedDate}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    gender
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.gender}
                  </p>
                </div>
              </div>
            </section>
          </div>
          <section className="p-4 pt-8 pb-1.5">
            <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
              Professional details
            </h1>

            <div className="w-full grid grid-cols-4 gap-4 place-content-center">
              <div className="singleField">
                <p className="subHeadingText   capitalize line-clamp-1">
                  Specialization
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.specialization?.name}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText   capitalize line-clamp-1">
                  Registration ID
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.regNumber}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText capitalize line-clamp-1">
                  State Medical Council
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.smCouncil}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText capitalize line-clamp-1">
                  Date of Registration
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  <Moment format="DD/MM/YYYY" date={data?.profile.dateOfReg} />
                </p>
              </div>
            </div>
            <div className="mt-6">
              <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
                Reference Contact
              </h1>
              <div className="w-full grid grid-cols-4 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">Name</p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.reference?.contactName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Contact Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode}{' '}
                    {data?.profile?.reference?.contactNo}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
                Practicing Hospital
              </h1>
              <div className="w-full grid grid-cols-4 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Name
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.practiceHospital || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalNumber || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Mobile Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalMobileNumber || '-'}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="px-4">
          <div className="flex items-center">
            <h1 className="headingText capitalize">consultation history</h1>
          </div>
          <div className="mt-6">
            <PaginatedTable
              itemsPerPage={10}
              tableHeaders={TableHeaders}
              items={data?.consultationHistory?.map((item) => {
                return {
                  hositalName: (
                    <p className="line-clamp-1">{item?.hositalName}</p>
                  ),
                  _id: item._id,
                  apppointmneType:
                    item?.apppointmneType === 'inperson'
                      ? 'In-person'
                      : 'Remote',
                  appointmentDate: (
                    <Moment format="DD/MM/YYYY" date={item.appointmentDate} />
                  ),
                  priority: item?.priority === 'regular' ? 'General' : 'Urgent',
                  Payment: <center>{item?.payment?.[0]} </center>,
                };
              })}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

export default ViewInformation;
