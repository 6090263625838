import React from 'react';
import DashboardSidenav from '../Sidebar/Sidebar';
import SearchHeader from './SearchHeader';

const AdminLayout = ({ children ,titleTab}) => {
  const urlPathLocation = window.location.pathname;
  return (
    <div className="flex ">
      <DashboardSidenav />
      <div className="w-full min-h-screen bg-[#FAFAFE] xl:bg-bodybackground">
        <SearchHeader titleTab={titleTab} />
        <div className={'content '}>{children}</div>
      </div>
    </div>
  );
};
export default AdminLayout;
