import React, { useState, useEffect, useContext } from 'react';
import { FaAddressCard, FaImage } from 'react-icons/fa';
import { PiCertificateFill } from 'react-icons/pi';
import { LuDownload } from 'react-icons/lu';
import { ApproveorReject, getDoctorById, getHospitalById } from '../../apis';
import toast from 'react-hot-toast';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../common/loader';
import Moment from 'react-moment';
import Noprofile from '../../Assets/no-image.png';
import { saveAs } from 'file-saver';
function ViewNewRequest({ id, closeFunction }) {
  const [requestDecline, setRequestDecline] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [ApproveLoader, setApproveLoader] = useState(false);
  const [RejectLoader, setRejectLoader] = useState(false);
  const [data, setdata] = useState(null);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getDoctorById(id);
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, []);

  const { userDetails } = useContext(AppContext);
  const ApproveRequest = async () => {
    try {
      setApproveLoader(true);
      const res = await ApproveorReject({
        type: 'doctor',
        status: 'Approve',
        doctorid: id,
        ApproverId: userDetails?.Profile?._id || '',
      });
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        toast.success(res.data.message);
        location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error ||
          'Something Went Wrong, Please try again later',
        { id: 'error' }
      );
    } finally {
      setApproveLoader(false);
    }
  };
  const RejectRequest = async () => {
    try {
      setRejectLoader(true);
      const res = await ApproveorReject({
        type: 'doctor',
        status: 'Reject',
        doctorid: id,
        ApproverId: userDetails?.Profile?._id || '',
        rejectnotes: rejectfeedback,
      });
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        toast.success(res.data.message);
        location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error ||
          'Something Went Wrong, Please try again later',
        { id: 'error' }
      );
    } finally {
      setApproveLoader(false);
    }
  };
  const [rejectfeedback, setrejectfeedback] = useState('');
  const date = new Date(data?.profile?.birthDate);
  const formattedDate = date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return (
    <div className="bg-white  rounded-2xl text-coalBlack py-5 max-w-xl ">
      <header className=" sticky top-0 z-10 bg-white pl-8 pr-8 pb-4 space-y-4 border-b border-gray-100  gap-6">
        <div className="flex items-center bg-white gap-5">
          <h1 className="headingText capitalize">Doctor registration</h1>
          <button
            className="capitalize text-base font-medium text-navLink ms-auto"
            onClick={() => {
              closeFunction();
            }}
          >
            close
          </button>
        </div>
        <div className="inline-flex gap-4 items-center ms-auto">
          {!requestDecline ? (
            <button
              className="px-4 py-2 min-h-10 baseText bg-green-500 text-white rounded-lg min-w-28"
              onClick={() => {
                ApproveRequest();
              }}
            >
              {ApproveLoader ? <EditProfileLoader /> : 'Approve'}
            </button>
          ) : null}
          {!requestDecline && (
            <button
              className="px-4 py-2 min-h-10 bg-red-500 baseText text-white rounded-lg min-w-28"
              onClick={() => {
                setRequestDecline(true);
              }}
            >
              Decline
            </button>
          )}
        </div>
      </header>
      {requestDecline ? (
        <div className="p-8 min-w-[35rem]">
          <p className="text-coalBlack subHeadingText ">
            Provide reason to decline verification request
          </p>
          <div className="my-10">
            <textarea
              name="requestDecline"
              className="border rounded-lg border-gray-200 p-4 w-full"
              rows="6"
              placeholder="Enter your response here"
            ></textarea>
            <div className="my-2">
              <div className="flex items-center justify-end gap-5">
                <button
                  className="px-4 py-2 min-h-10 baseText  text-accent border border-accent rounded-lg min-w-28 capitalize"
                  onClick={() => {
                    setRequestDecline(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="px-4 py-2 min-h-10 baseText bg-accent text-white rounded-lg min-w-28 capitalize"
                  onClick={() => {
                    RejectRequest();
                  }}
                >
                  {RejectLoader ? <EditProfileLoader /> : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className="p-8 flex flex-col gap-6">
          <div className="max-w-[230px] w-full">
            {Loader ? (
              <div
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex"
              >
                <FaImage size={60} className="text-white" />
              </div>
            ) : data?.profile?.coverPic ? (
              <img
                src={data.profile.coverPic}
                alt="Cover"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 object-cover"
              />
            ) : (
              <img
                src={Noprofile}
                alt="No Profile"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 "
              />
            )}
          </div>
          <div className="w-full space-y-10">
            <div className="block">
              <h2 className="headingText mb-3">Personal details</h2>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">Name</p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.DoctorName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Email
                  </p>
                  <p className="baseText  break-all text-coalBlack">
                    {data?.profile?.userId?.email}
                  </p>
                </div>

                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">DOB</p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {formattedDate}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    gender
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.gender}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    phone number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode} {data?.profile?.phoneNo}
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <h2 className="headingText mb-3">Professional Details</h2>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    specialization
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.specialization?.name}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Registration Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.regNumber}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    State Medical Council
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.smCouncil}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Date of Registration
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    <Moment
                      format="DD/MM/YYYY"
                      date={data?.profile.dateOfReg}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <h2 className="headingText mb-3">Hospital details</h2>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Practicing Hospital
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.practiceHospital || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalNumber || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Mobile Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalMobileNumber || '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <h2 className="headingText mb-3">Reference Contact</h2>
              <div className="grid grid-cols-2 gap-x-4 gap-y-5">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">Name</p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.reference?.contactName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Contact Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode}{' '}
                    {data?.profile?.reference?.contactNo}
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <h2 className="headingText mb-3">Submitted Documents</h2>
              {data?.profile?.documents.length == 0 ? (
                <p>No documents Submitted !</p>
              ) : null}
              {data?.profile?.documents?.map((data) => {
                return (
                  <div
                    className="rounded-2xl shadow-md p-4 px-8 flex items-center mb-2 cursor-pointer"
                    onClick={() => saveAs(data.filePath)}
                  >
                    <div className="inline-flex gap-5 items-center">
                      <FaAddressCard className="text-2xl text-accent inline-block " />
                      <p className="baseText capitalize line-clamp-1 text-coalBlack border-l border-gray-400 ps-2">
                        {data.fileName ||
                          data.filePath.substring(
                            data.filePath.lastIndexOf('/') + 1
                          )}
                      </p>
                    </div>
                    <LuDownload className="text-2xl text-coalBlack ms-auto" />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default ViewNewRequest;
