import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { AdminDashboardContext } from '../../appContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AppointmentsChart = () => {
  const { dashboardDetails, setDashboardDetails, applyFilter } = useContext(
    AdminDashboardContext
  );
  let labels = [];
  let inPerson = [];
  let virtual = [];
  console.log('Appointmenthistory');
  console.log(dashboardDetails?.Appointmenthistory);
  dashboardDetails?.Appointmenthistory?.map((data) => {
    console.log(data);
    labels.push(data?._id);
    inPerson.push(data?.noOfInpersonConsultation);
    virtual.push(data?.noOfVirtualConsultation);
  });
  console.log(labels);
  console.log(inPerson);
  console.log(virtual);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'In-person Appointments',
        data: inPerson,
        backgroundColor: '#3A57E8',
        barThickness: 12,
        borderRadius: 8,
      },
      {
        label: 'Virtual Appointments',
        data: virtual,
        backgroundColor: '#85F4FA',
        barThickness: 12,
        borderRadius: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 8,
          boxHeight: 8,
          font: {
            family: 'Poppins',
          },
        },
      },
      title: {
        display: true,
        text: 'Appointments Distribution',
        font: {
          family: 'Poppins',
          size: 18,
          weight:500
        },
      },
      tooltip: {
        titleFont: {
          family: 'Poppins',
        },
        bodyFont: {
          family: 'Poppins',
        },
        footerFont: {
          family: 'Poppins',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          // text: 'Number of Appointments',
          font: {
            family: 'Poppins',
          },
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          // text: 'Days',
          font: {
            family: 'Poppins',
          },
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
    },
  };

  return (
    <div style={{ marginBottom: '14px', minHeight: '250px' }}>
      <Bar data={data} options={options} height={300} />
    </div>
  );
};

export default AppointmentsChart;
