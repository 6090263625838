import React, { useContext, useEffect, useRef, useState } from 'react';
import { GoArrowUpRight } from 'react-icons/go';

function BookingsCard({
  imgUrl,
  bookingTitle,
  bookingCount,
  bookingPercentage,
  bookingFromDate,
}) {
  return (
    <div className="space-y-7 bg-[#F8FAFC] p-4 rounded-2xl h-full shadow-blue_dropshadow lg:min-h-max">
      <div className="flex items-center gap-4">
        <div className="size-11">
          <img src={imgUrl} className="w-full aspect-square" />
        </div>
        <p className="subHeadingText text-coalBlack capitalize">{bookingTitle}</p>
      </div>
      <div className="flex gap-2 items-center ps-5">
        <p className="headingText text-coalBlack  text-center">
          {bookingCount}
        </p>
        
      </div>
    </div>
  );
}

export default BookingsCard;
