import React, { useContext, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { AdminDashboardContext } from '../../appContext';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WaveLineChart = () => {
  const { dashboardDetails } = useContext(AdminDashboardContext);
  const [year, setYear] = useState(new Date().getFullYear());

  const labels = dashboardDetails?.consultationcountgraph?.map(data => data?._id) || [];
  const completedCon = dashboardDetails?.consultationcountgraph?.map(data => data?.noOfCompletedConsultation) || [];
  const pendingCon = dashboardDetails?.consultationcountgraph?.map(data => data?.noOfPendingConsultation) || [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Completed Consultation',
        data: completedCon,
        borderColor: '#3A57E8',
        backgroundColor: '#3A57E8',
        fill: false,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 7,
      },
      {
        label: 'Pending Consultation',
        data: pendingCon,
        borderColor: '#85F4FA',
        backgroundColor: '#85F4FA',
        fill: false,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 7,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 8,
          boxHeight: 8,
          generateLabels: (chart) => {
            const original = ChartJS.defaults.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);
            labelsOriginal.forEach((label) => {
              label.paddingBottom = 44; // Add margin bottom to legend labels
            });
            return labelsOriginal;
          },
          font: {
            family: 'Poppins',
          },
        },
      },
      title: {
        display: true,
        text: `Consultation Data for ${year}`,
        font: {
          family: 'Poppins',
          size: 18,
          weight:500,
          color:'red'
        },
      },
      tooltip: {
        titleFont: {
          family: 'Poppins',
        },
        bodyFont: {
          family: 'Poppins',
        },
        footerFont: {
          family: 'Poppins',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Number of Consultations',
          font: {
            family: 'Poppins',
          },
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
    },
  };

  return (
    <div className='bg-white p-4 rounded-2xl'>
      <Line data={data} options={options} />
    </div>
  );
};

export default WaveLineChart;
