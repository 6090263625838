import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import '../tableList.css';
import Moment from 'react-moment';
import { FaArrowRight } from 'react-icons/fa';
import MainOddsPopup from '../../Modals/MainOddsPopup';
import ViewInformation from '../../DoctorManagementViews/DoctorViewInformation';


function ReportListDoctor({ hit, columnWidth }) {
  console.log(hit);
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText cursor-pointer"
            onClick={handleViewNewrequest}
          >
            <p className="char15 capitalize">{hit?.Name}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.PhoneNumber}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <Moment format="DD/MM/YYYY" date={hit?.RegisteredDate} />
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15 capitalize">{hit?.specialization?.[0]}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText text-center"
          >
            {hit?.NoofCompletedConsultation}
          </div>
        </div>
      </div>
      {viewRequest && (
        <MainOddsPopup closeFunction={handleViewNewrequest}>
            <ViewInformation id={hit?._id} closeFunction={handleViewNewrequest} />
        </MainOddsPopup>
      )}
    </div>
  );
}

export default ReportListDoctor;
