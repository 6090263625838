import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';
import { FaArrowRight } from 'react-icons/fa';
import MainOddsPopup from '../Modals/MainOddsPopup';
import ViewNewRequest from '../HospitalManagementViews/HospitalViewNewRequest';
import ViewInformation from '../HospitalManagementViews/HospitalViewInformation';

function TableListCredit({ hit, columnWidth }) {
  console.log(hit);
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15">{hit?.hospitalName}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="char15">{hit?.Email?.[0]}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.PhoneNumber}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <p className="ps-5">{hit?.lastPaidInvoiceAmount}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.lastPaidInvoiceDate != '' ? (
              <Moment format="DD/MM/YYYY" date={hit?.lastPaidInvoiceDate} />
            ) : (
              <p className="ps-4">---</p>
            )}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <a className="text-coalBlack" onClick={handleViewNewrequest}>
              View <FaArrowRight className="inline-block ms-1" />
            </a>
          </div>
        </div>
      </div>
      {viewRequest && (
        <MainOddsPopup closeFunction={handleViewNewrequest}>
          {hit?.isApproved ? (
            <ViewInformation
              id={hit?._id}
              closeFunction={handleViewNewrequest}
            />
          ) : (
            <ViewNewRequest
              id={hit?._id}
              closeFunction={handleViewNewrequest}
            />
          )}
        </MainOddsPopup>
      )}
    </div>
  );
}

export default TableListCredit;
