import React,{useRef} from 'react';
import AdminLayout from '../../components/admin-layout';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import { FaFilter } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';
import MainOddsPopup from '../../components/Modals/MainOddsPopup';
import { useState } from 'react';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import ViewPayoutRequest from '../../components/payoutManagementViews/ViewPayoutRequest';
import ViewPayoutDetails from '../../components/payoutManagementViews/ViewPayoutDetails';
import TypesensePayoutList from '../../components/TypesenseList/TypesensePayout';
function PayoutIndex() {
  const titleTab = {
    active: true,
    heading: 'Payout management',
    btnBlock: false,
    btn: null,
    imgBlock: true,
    img: (
      <img
        src={require('../../images/payoutIcon/vuesax/bulk/vuesax/bulk/direct-up.png')}
        className="object-cover rounded-full"
      />
    ),
  };

  const [step, setStep] = useState(1);
  const [sortOpt, setSortOpt] = useState("asc");
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-payout',
          sort_by: `RequestOn:${(sortOpt==='asc') ? 'desc':'asc'}`,
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchInputRef = useRef(null);
  const searchClient = typesenseInitiate();
  const [searchresult,setsearchresult]=useState(0);
  const TableHeaders = [
    'doctor NAME',
    'PHONE NUMBER',
    'account number',
    'requested amount',
    'requested on',
    '',
  ];
  return (
    <AdminLayout titleTab={titleTab}>
      <div className="px-6">
        <div className="min-h-20 p-4 bg-white rounded-2xl capitalize flex items-center">
          <p className="text-2xl text-navyBlue font-semibold">
            Payout management
          </p>
          <div className="inline-flex items-center gap-5 ms-auto">
            <SimpleButton
              title={'Payout Request'}
              customClass={'rounded-full !px-5'}
              buttonType={step === 1 ? 'primary' : 'unfilled'}
              onClickEvent={() => {
                setStep(1);
              }}
            />

            <SimpleButton
              title={'Payout History'}
              customClass={'rounded-full !px-5 '}
              buttonType={step === 2 ? 'primary' : 'unfilled'}
              onClickEvent={() => {
                setStep(2);
              }}
            />
          </div>
        </div>

        <section className=" bg-white rounded-2xl p-5 my-6 relative">
          <div className="flex items-center capitalize">
            <div className="capitalize font-medium">
              <p className="text-base text-coalBlack capitalize">
                Payment request list
              </p>
            </div>
            <div className="ms-auto">
            <div className="inline-block bg-accent rounded-lg p-2 shadow-blue_dropshadow relative cursor-pointer group/sort">
                  <FaFilter className="text-2xl text-white" />
                  <div className="absolute top-full -left-20 bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible ">
                    <p
                      className={`${
                        sortOpt == "asc"
                          ? "text-white bg-accent hover:!bg-accent"
                          : ""
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1 `}
                      onClick={() => {
                        setSortOpt("asc");
                      }}
                    >
                      Newest
                    </p>
                    <p
                      className={`${
                        sortOpt == "desc"
                          ? "text-white bg-accent hover:!bg-accent"
                          : ""
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1`}
                      onClick={() => {
                        setSortOpt("desc");
                      }}
                    >
                      Oldest
                    </p>
                  </div>
                </div>
            </div>
          </div>
          <div className="mt-10">
          <TypesensePayoutList
          searchClient={searchClient} 
          searchInputRef={searchInputRef}
          setsearchresult={setsearchresult}
          step={step}
          />
          </div>
        </section>
      </div>
     
    </AdminLayout>
  );
}

export default PayoutIndex;
