import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';

function MedicalRecord({ closeFunction, data }) {
  console.log(data);
  console.log(data?.record?.Details);
  return (
    <section className="fixed top-0 left-0 min-h-screen h-screen w-full bg-white z-30 xl:bg-black/25 xl:flex xl:justify-center xl:items-center">
      <div className="bg-white relative p-4 pt-0 z-20 m-auto overflow-y-auto scrollbar w-full h-full max-h-screen lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl xl:bg-bodybackground">
        <div className=" bg-white flex items-center sticky top-0 py-4 z-30 xl:bg-inherit">
          <div className="flex items-center gap-1">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={closeFunction}
            />
            <p className="text-lg font-medium text-coalBlack capitalize">
              All Records
            </p>
          </div>
          <p
            className="text-gray-500 text-sm font-medium min-w-10 p-2 cursor-pointer hidden xl:block ms-auto"
            onClick={closeFunction}
          >
            close
          </p>
        </div>
        <main className="pb-20 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 place-content-center">
          {data?.record?.Details?.length == 0 ? (
            <p className="my-20 text-base font-normal text-coalBlack text-center lg:col-span-3">
              No records Uploaded
            </p>
          ) : (
            data?.record?.Details?.map((file, index) => (
              <MrCard file={file} datarecords={data} />
            ))
          )}
        </main>
      </div>
    </section>
  );
}

function MrCard({ file, datarecords }) {
  function getreporttypeText() {
    const text = {
      report: 'Report',
      prescription: 'Prescription',
      notes: 'Notes',
    };
    return text[file?.type];
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className="flex items-start gap-3 p-2.5 shadow-blue_dropshadow rounded-sm bg-white">
      {/* <div className="min-w-16 h-16 rounded-xl bg-gray-200"></div> */}
      <img
        src={file?.filePath}
        className="min-w-16 h-16 rounded-xl bg-gray-200"
        alt={file?.fileName}
      />
      <div className="space-y-1">
        <p className="text-black text-sm font-medium">
          Medication {getreporttypeText()}
        </p>
        <p className="text-gray-200 font-normal text-[11px]">
          {formatDate(datarecords?.createdAt)}
        </p>
        <p className="text-center text-xs font-medium text-accent bg-lightBlue rounded-md px-4 py-1.5 inline-block">
          {getreporttypeText()}
        </p>
      </div>
    </div>
  );
}

export default MedicalRecord;
