import React, { useState, useEffect } from 'react';
import PaginatedTable from '../TableOdds/PaginatedTable';
import { getHospitalById } from '../../apis';
import toast from 'react-hot-toast';
import axios from 'axios';
import Moment from 'react-moment';
import Noprofile from '../../Assets/no-image.png';
import { FaImage } from 'react-icons/fa';

function ViewInformation({ id,closeFunction }) {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getHospitalById(id);
      if (res.status === 200) {
        console.log(res.data);
        setData(res.data);
        setLoader(false); // Set loader to false after data is loaded
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      // toast.error(message, { id: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const TableHeaders = [
    'Hospital Name',
    'Consultation Type',
    'Appointment Date',
    'Priority',
    'Status',
  ];

  return (
    <div className="bg-white rounded-2xl text-coalBlack py-5">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 border-b border-gray-100 mb-10">
        <h1 className="text-2xl font-medium capitalize"> {data?.profile?.hospitalName}</h1>
        <button
          className="capitalize text-base font-medium text-navLink ms-auto"
          onClick={() => {
            closeFunction();
          }}
        >
          close
        </button>
      </header>
      <main className="space-y-10 px-4">
        <section className="flex flex-row gap-6 shadow-blue_dropshadow2 p-4 rounded-[58px]">
          <div className="max-w-[230px] w-full">
            {loader ? (
              <div className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-slate-200 object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex">
                <FaImage size={60} className="text-white"/>
              </div>
            ) : data?.profile?.coverPic ? (
              <img
                src={data.profile.coverPic}
                alt="Cover"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-slate-200 object-cover"
              />
            ) : (
              <img
                src={Noprofile}
                alt="No Profile"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-slate-200"
              />
            )}
          </div>
          <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-x-16 gap-y-6 place-content-center">
            <div className="singleField">
              <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                Email
              </p>
              <p className="baseText break-all text-coalBlack/75">
                {data?.profile?.userId?.email}
              </p>
            </div>
            <div className="singleField">
              <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                Phone Number
              </p>
              <p className="baseText capitalize line-clamp-1 text-coalBlack/75">
                {data?.profile?.refCountryCode} {data?.profile?.refPhoneNo}
              </p>
            </div>
            <div className="singleField">
              <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                Registration ID
              </p>
              <p className="baseText capitalize line-clamp-1 text-coalBlack/75">
                {data?.profile?.regNumber}
              </p>
            </div>
            <div className="singleField col-span-3">
              <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                Address
              </p>
              <p className="baseText capitalize line-clamp-2 text-coalBlack/75">
                {data?.profile?.address}
              </p>
            </div>
          </div>
        </section>
        <section className="px-4">
          <div className="flex items-center">
            <h1 className="headingText capitalize">consultation history</h1>
          </div>
          <div className="mt-8">
            <PaginatedTable
              itemsPerPage={10}
              tableHeaders={TableHeaders}
              items={data?.consultationHistory?.map((item) => {
                return {
                  hositalName: <p className="line-clamp-1">{ item?.hositalName}</p>,
                  apppointmneType: item.apppointmneType === 'inperson' ? 'In-person' : 'Remote',
                  appointmentDate: <Moment format="DD/MM/YYYY" date={item.appointmentDate} />,
                  priority: item.priority === 'regular' ? 'General' : 'Urgent',
                  status: item.status,
                };
              })}
            />
          </div>
        </section>
     
      </main>
    </div>
  );
}

export default ViewInformation;
