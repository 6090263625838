import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {
  getCreditBasePrice,
  getSpecialization,
  updateCreditDetails,
} from '../../apis';

function SettingsManagement() {
  const titleTab = {
    active: true,
    heading: 'Settings management',
    btnBlock: false,
    btn: null,
  };
  const [data, setData] = useState(null);
  const [creditDetails, setCreditDetails] = useState(null);
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const toGetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        console.log(res?.data?.result);
        setData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    toGetCreditBasePrice();
    toGetSpecialization();
  }, []);
  return (
    <AdminLayout titleTab={titleTab}>
      <section className="p-6">
        <section className=" bg-white rounded-2xl p-5 relative gap-6 flex ">
          <section className="w-fit bg-gray-100/75 rounded-xl p-3">
            <p className="subHeadingText text-coalBlack mb-4">
              Specialization Cost
            </p>
            <p className="smallText mb-3 text-navLink ">
              <AiOutlineInfoCircle className="inline-block me-1" />
              This amount will be charged for hospitals to book consultations
              with these specializations.
            </p>
            <div className="grid grid-cols-2 gap-x-10 mb-3 gap-y-4 max-w-fit ">
              {data?.map((data) => (
                <>
                  <p className="baseText  text-coalBlack capitalize">
                    {data?.name}
                  </p>
                  <p className="baseText md:!font-medium text-coalBlack capitalize text-end">
                    {data?.Amount} ₹
                  </p>
                </>
              ))}
            </div>
          </section>
          <section className="w-fit bg-gray-100/75 rounded-xl p-3 ">
            {' '}
            <p className="subHeadingText text-coalBlack mb-4">
              Consultation Cost
            </p>
            <p className="smallText mb-3 text-navLink ">
              <AiOutlineInfoCircle className="inline-block me-1" />
              This amount will be charged for hospitals to create a In-person &
              Virtual consultations
            </p>
            <div className="grid grid-cols-2 gap-x-10 mb-3 gap-y-4 max-w-fit">
              <p className="baseText  text-coalBlack capitalize">
                In-person ConsultationCost
              </p>
              <p className="baseText md:!font-medium text-coalBlack capitalize text-end self-center">
                {creditDetails?.InpersonConsultationCost} ₹
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-10 mb-3 gap-y-4 max-w-fit">
              <p className="baseText  text-coalBlack capitalize">
                Virtual ConsultationCost
              </p>
              <p className="baseText md:!font-medium text-coalBlack capitalize text-end self-center">
                {creditDetails?.VirtualConsultationCost} ₹
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-10 mb-3 gap-y-4 max-w-fit">
              <p className="baseText  text-coalBlack capitalize">
                Commission Percentage
              </p>
              <p className="baseText md:!font-medium text-coalBlack capitalize text-end self-center">
                {creditDetails?.CommissionPercentage} %
              </p>
            </div>
          </section>
          <section className="w-fit bg-gray-100/75 rounded-xl p-3 ">
            {' '}
            <p className="subHeadingText text-coalBlack mb-4">
              Amount billing cycle
            </p>
            <p className="smallText mb-3 text-navLink ">
              <AiOutlineInfoCircle className="inline-block me-1" />
              Each Amount billing Cycle starts at month 1 and ends at the month's last date.
            </p>
            <div className="mb-3">
              <p className="baseText  text-coalBlack">
                Hospital due dates would be on the{' '}
                <span className="md:!font-medium ">
                  {creditDetails?.HospitalPayDate} <sup>th </sup>
                   of every month.
                </span>
              </p>
            </div>
            <div className="">
              <p className="baseText  text-coalBlack">
                Doctors can withdraw the current month's earnings on the{' '}
                <span className="md:!font-medium ">
                  {creditDetails?.DoctorpayDate} <sup>th </sup>
                  of each month.
                </span>
              </p>
            </div>
          </section>
        </section>
      </section>
    </AdminLayout>
  );
}

export default SettingsManagement;
