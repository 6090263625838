import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import '../tableList.css';
import Moment from 'react-moment';

function ConsultationList({hit, columnWidth}) {
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  const checkStatus=(requestedDate, status)=> {
    const today = new Date();
    const inputDate = new Date(requestedDate);
  
    // Reset time part of today for accurate date comparison
    today.setHours(0, 0, 0, 0);
  
    if (status === 'pending' || status==='Pending') {
      if (inputDate < today) {
        return(
          <p className="min-w-16 text-center capitalize smallText text-red-500 bg-red-200 rounded-lg p-1 px-2 inline-block">
                  Expired
          </p>
        );
      }
      else{
        return(
          <p className="min-w-16 text-center capitalize smallText text-pink-500 bg-pink-200 rounded-lg p-1 px-2 inline-block">
                  open
          </p>
        );
      }  
    } else if (status === 'completed' || status==='Completed') {
      return(
        <p className="min-w-16 text-center capitalize smallText text-green-500 bg-green-200 rounded-lg p-1 px-2 inline-block">
                Completed
        </p>
      );
    } else {
      return 'Unknown Status';
    }
    
  };
  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item' >
          
          <div style={{width: columnWidth}} className='text-coalBlack smallText cursor-pointer line-clamp-1 me-4' >
            {hit?.HospitalName}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText line-clamp-1'>
            {hit?.Specalaization?.[0]}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText line-clamp-1'>
            {hit?.DoctorName?.[0]}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText line-clamp-1'>
            <Moment format='DD/MM/YYYY' date={hit?.CreatedDate} />
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText '>
            {hit?.consultationCompletedDate ? <Moment format='DD/MM/YYYY' date={hit?.consultationCompletedDate} />:'-'}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
            {checkStatus(hit?.RequestedDate,hit?.Status)}
          </div>
         
         
        </div>
      </div>
      {/* {
        viewRequest &&
         <MainOddsPopup closeFunction={handleViewNewrequest}>
         <ViewInformation id={hit._id} closeFunction={handleViewNewrequest} />
         </MainOddsPopup>
      } */}
    </div>
    
  );
}

export default  ConsultationList;
