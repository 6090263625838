import React from 'react';

function MainOddsPopup({ children, closeFunction }) {
  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[9999]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      <div className=" relative z-20 m-auto overflow-y-auto scrollbar   max-h-screen  lg:w-auto lg:max-w-4xl xl:max-w-5xl  lg:max-h-[96vh]  lg:rounded-3xl lg:rounded-tr-none lg:rounded-br-none overscroll-contain">
        {children}
      </div>
    </section>
  );
}

export default MainOddsPopup;
