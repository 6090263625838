import { useFormik } from 'formik';

import React, { useCallback, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { useDropzone } from 'react-dropzone';
import { createNewSubscriptionPlan, fileUploadApi } from '../../apis';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';
import { createPlanValidation } from '../Validation';
import { InputErrorMessage } from '../errorMessages';

function CreatePlane({ closeFunction }) {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [profileUploadPercentage, setProfileUploadPercentage] = useState(0);
  const [profileName, setProfileName] = useState('');
  const [profileSize, setProfileSize] = useState('');
  const [profileIsError, setProfileIsError] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileIsError, setFileIsError] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [option, setOption] = useState(null);

  const selectOption = [
    { value: 'yearly', label: 'Yearly' },
    { value: 'monthly', label: 'Monthly' },
  ];

  const formik = useFormik({
    initialValues: {
      planIcon: '',
      planName: '',
      planDis: '',
      discountPercentage: '',
      discountDis: '',
      planAmount: '',
      // credits: '',
      planType: '',
    },
    validationSchema: createPlanValidation,
    onSubmit: async (values) => {
      setLoader(true);
      const {
        planIcon,
        planName,
        planDis,
        discountPercentage,
        discountDis,
        planAmount,
        // credits,
        planType,
      } = values;
      const payload = {
        period: planType,
        planName: planName,
        planDescription: planDis,
        planAmount: planAmount,
        discountPercentage: discountPercentage,
        discountDescription: discountDis,
        // noOfCredit: credits,
        planIcon: planIcon,
        userId: localStorage.getItem('userId'),
      };
      console.log(payload);
      try {
        const res = await createNewSubscriptionPlan(payload);
        if (res.status == 200) {
          setLoader(false);
          closeFunction();
          toast.success('New plan created successfully');
        }
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setLoader(false);
      }
    },
  });

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return ` ${sizeInBytes} bytes`;
    }
  };

  const onUploadProgress = (progressEvent, file) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercentage(progress);
    setFileName(file.name);
    setFileSize(formatFileSize(file.size));
  };

  const fileUpload = async (file) => {
    try {
      setIsFile(true);
      const formData = new FormData();
      formData.append('file', file);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        formik.setFieldValue('planIcon', uploadResponse.data.filePath);
        console.log(uploadResponse.data.filePath);
      } else {
        setFileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setFileIsError(true);
    } finally {
      setIsFile(false);
    }
  };

  const profileUpload = async (file) => {
    try {
      setIsProfile(true);
      const formData = new FormData();
      formData.append('file', file);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        formik.setFieldValue('planIcon', uploadResponse.data.filePath);
        console.log(uploadResponse.data.filePath.split('-')[2]);
      } else {
        setProfileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setProfileIsError(true);
    } finally {
      setIsProfile(false);
    }
  };

  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profileUpload(file);
    }
  }, []);

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
  });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await fileUpload(file);
    }
  };
  const handleSelect = (opt) => {
    setOption(opt);
    formik.setFieldValue('planType', opt.value);
  };

  return (
    <div className="bg-white rounded-2xl text-coalBlack py-5 min-w-[800px] overflow-x-hidden">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 py-6 border-b border-gray-100 mb-10">
        <h1 className="headingText capitalize">Create new plan</h1>
        <button
          className="baseText !font-medium ms-auto text-navLink"
          onClick={() => closeFunction()}
        >
          Discard
        </button>
      </header>

      <form
        className="grid grid-cols-2 p-7 pt-0 gap-x-10"
        onSubmit={formik.handleSubmit}
      >
        <section className="grid grid-flow-row gap-y-6">
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">upload image</p>

            <div className="relative cursor-pointer" {...getRootPropsProfile()}>
              <div className="flex gap-2 items-center justify-between shadow-md rounded-2xl p-3 bg-white">
                <p className="baseText capitalize text-navLink line-clamp-1">
                  {formik.values.planIcon != ''
                    ? formik.values.planIcon.split('-')[2]
                    : 'Upload Icon'}
                </p>

                <div className="relative ms-auto">
                  <FiUpload className="text-2xl text-navLink" />
                </div>
                <input
                  {...getInputPropsProfile()}
                  placeholder="plan icon"
                  type="file"
                  name="planIcon"
                  id="planIcon"
                  className="fileInput"
                  onChange={handleFileChange}
                  accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </div>
            </div>
            <InputErrorMessage
              error={
                formik.touched.planIcon &&
                    formik.errors.planIcon
              }
            />
          </div>
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">plan name</p>
            <div className="planName">
              <div className="relative">
                <input
                  type="text"
                  name="planName"
                  className={
                    Boolean(formik.touched.planName) &&
                    Boolean(formik.errors.planName)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="planName"
                  placeholder="Enter text here"
                  value={formik.values.planName}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={
                    formik.touched.planName &&
                    formik.errors.planName
                  }
                />
              </div>
            </div>
          </div>
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">plan description</p>

            <div className="planDis">
              <div className="relative">
                <input
                  type="text"
                  name="planDis"
                  className={
                    Boolean(formik.touched.planDis) &&
                    Boolean(formik.errors.planDis)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="planDis"
                  placeholder="Enter text here"
                  value={formik.values.planDis}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={
                    formik.touched.planDis &&
                    formik.errors.planDis
                  }
                />
              </div>
            </div>
          </div>

          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">Select plan</p>
            <Select
              onChange={handleSelect}
              value={option}
              options={selectOption}
            />
            <InputErrorMessage
              error={
                formik.touched.planType &&
                    formik.errors.planType
              }
            />
          </div>
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">plan Amount</p>
            <div className="planAmount">
              <div className="relative">
                <input
                  type="text"
                  name="planAmount"
                  className={
                    Boolean(formik.touched.planAmount) &&
                    Boolean(formik.errors.planAmount)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="planAmount"
                  placeholder="Enter Amount"
                  value={formik.values.planAmount}
                  onChange={handleChange}
                />
                <div className='max-w-xs'>
                  <InputErrorMessage
                    error={
                      formik.touched.planAmount &&
                    formik.errors.planAmount
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">No of credits</p>
            <div className="credits">
              <div className="relative">
                <input
                  type="text"
                  name="credits"
                  className={
                    Boolean(formik.touched.credits) &&
                    Boolean(formik.errors.credits)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="credits"
                  placeholder="Enter credits"
                  value={formik.values.credits}
                  onChange={handleChange}
                />
                <div className='max-w-xs'>
                  <InputErrorMessage
                    error={
                      formik.touched.credits &&
                    formik.errors.credits
                    }
                  />
                </div>
              </div>
            </div>
          </div> */}
        </section>
        <section className="block space-y-6">
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">
              discount percentage
            </p>

            <div className="discountPercentage">
              <div className="relative">
                <input
                  type="text"
                  name="discountPercentage"
                  className={
                    Boolean(formik.touched.discountPercentage) &&
                    Boolean(formik.errors.discountPercentage)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="discountPercentage"
                  placeholder="Enter discount percentage"
                  value={formik.values.discountPercentage}
                  onChange={handleChange}
                />
                <div className='max-w-xs'>
                  <InputErrorMessage
                    error={
                      formik.touched.discountPercentage &&
                    formik.errors.discountPercentage
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="singleField">
            <p className="subHeadingText mb-2 capitalize">
              discount description
            </p>

            <div className="discountDis">
              <div className="relative">
                <input
                  type="text"
                  name="discountDis"
                  className={
                    Boolean(formik.touched.discountDis) &&
                    Boolean(formik.errors.discountDis)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="discountDis"
                  placeholder="Enter text here"
                  value={formik.values.discountDis}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={
                    formik.touched.discountDis &&
                    formik.errors.discountDis
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-auto">
            <SimpleButton
              title={loader ? <EditProfileLoader /> : 'Create'}
              buttonType={'primary'}
              customClass={'rounded-lg ms-auto'}
              onClickEvent={formik.handleSubmit}
            />
          </div>
        </section>
      </form>
    </div>
  );
}

export default CreatePlane;
