import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import '../tableList.css';
import Moment from 'react-moment';

function SubscriptionList({hit, columnWidth}) {
  const [viewRequest, setViewRequest] = useState(false);
  const handleViewNewrequest = () => {
    setViewRequest(!viewRequest);
  };
  const checkStatus=(status)=> {
    if (status === 'active') {
      return(
        <p className="min-w-16 text-center capitalize smallText text-green-500 bg-green-200 rounded-lg p-1 px-2 inline-block">
                  Active
        </p>
      );
    } else if (status === 'completed') {
      return(
        <p className="min-w-16 text-center capitalize smallText text-pink-500 bg-pink-200 rounded-lg p-1 px-2 inline-block">
                Completed
        </p>
      );
    }
    else if (status === 'created') {
      return(
        <p className="min-w-16 text-center capitalize smallText text-blue-500 bg-blue-200 rounded-lg p-1 px-2 inline-block">
              Created
        </p>
      );
    } else {
      return 'Unknown Status';
    }
    
  };
  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item' >
          
          <div style={{width: columnWidth}} className='text-coalBlack smallText cursor-pointer' >
            {hit?.hospitalName?.[0]}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
            {hit?.subscriptionId}
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText text-center'>
            {hit?.planName?.[0]}
          </div>
          {/* <div style={{width: columnWidth}} className='text-coalBlack smallText'>
          {hit?.planID?.[0]}
          </div> */}
          <div style={{width: columnWidth}} className='text-coalBlack smallText '>
            <Moment format='DD/MM/YYYY' date={hit?.PurchasedAt} />
          </div>
          <div style={{width: columnWidth}} className='text-coalBlack smallText'>
            {checkStatus(hit?.status)}
          </div>
         
         
        </div>
      </div>
      {/* {
        viewRequest &&
         <MainOddsPopup closeFunction={handleViewNewrequest}>
         <ViewInformation id={hit._id} closeFunction={handleViewNewrequest} />
         </MainOddsPopup>
      } */}
    </div>
    
  );
}

export default  SubscriptionList;