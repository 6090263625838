import React from 'react';

function CreditUpdateCard({ conType, message, updateField }) {
  return (
    <div className="bg-white p-5 rounded-[20px]  space-y-1 inline-block max-w-sm">
      <h2 className="subHeadingText text-navyBlue">{conType}</h2>
      <p className="baseText max-w-xs text-navLink min-h-12">
        {message}
      </p>
      <form>{updateField}</form>
    </div>
  );
}

export default CreditUpdateCard;
