import React, { useState,useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { FaFilter } from 'react-icons/fa';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import UpdateCredits from './UpdateCredits';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseHospitalList from '../../components/TypesenseList/TypesenseHospital';
import { FaArrowRight } from 'react-icons/fa6';
import TypesenseCreditList from '../../components/TypesenseList/TypesenseCredit';

function CreditIndex() {
  const [editBlock, setEditBlock] = useState(false);
  const [sortOpt, setSortOpt] = useState('asc');
  const handleEditBlock = () => {
    setEditBlock(!editBlock);
  };

  const titleTab = {
    active: true,
    heading: 'Payment management',
    btnBlock: true,
    btn: (
      <SimpleButton
        title={'Edit consultation cost'}
        buttonType={'primary'}
        customClass={'rounded-full !px-6'}
        onClickEvent={handleEditBlock}
      />
    ),
    imgBlock: true,
    img: <BiSolidDollarCircle className="text-yellow-400 text-7xl" />,
  };
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-hospitalswithprofiles',
          sort_by: `lastActiveDate:${(sortOpt==='asc') ? 'desc':'asc'}`,
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchInputRef = useRef(null);
  const searchClient = typesenseInitiate();
  const [searchresult,setsearchresult]=useState(0);
  return (
    <AdminLayout titleTab={titleTab}>
      <section className="p-6">
        <section className=" bg-white rounded-2xl p-5 relative">
          <div className="flex items-center capitalize">
            <div className="capitalize font-medium">
              <p className="baseText text-coalBlack">List of Hospitals</p>
              <p className="smallText text-navLink">{searchresult} available doctors</p>
            </div>
            <div className="ms-auto">
              <div className="inline-flex items-center gap-5">
                <div className="relative">
                </div>
                <div className="inline-block bg-accent rounded-lg p-2 shadow-blue_dropshadow relative cursor-pointer group/sort">
                  <FaFilter className="text-2xl text-white" />
                  <div className="absolute top-full -left-20 bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible ">
                    <p
                      className={`${
                        sortOpt == 'asc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1 `}
                      onClick={() => {
                        setSortOpt('asc');
                      }}
                    >
                      Newest
                    </p>
                    <p
                      className={`${
                        sortOpt == 'desc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1`}
                      onClick={() => {
                        setSortOpt('desc');
                      }}
                    >
                      Oldest
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <TypesenseCreditList searchClient={searchClient} 
              searchInputRef={searchInputRef}
              setsearchresult={setsearchresult}
            />
          </div>
        </section>
        {editBlock && <UpdateCredits closeFunction={handleEditBlock} />}
      </section>
    </AdminLayout>
  );
}

export default CreditIndex;
