import React from 'react';

function ReportTabs({ handleTabs, activeTab }) {
  const tabList = ['Doctor', 'Hospital', 'Monthly Earnings', 'Payout', 'Consultation'];
  
  return (
    <div className="bg-neutral-100 rounded-lg p-2 flex items-center gap-1 overflow-x-auto">
      {tabList.map(tab => (
        <button
          key={tab}
          className={`tabBtn px-4 py-2 text-coalBlack font-medium text-base rounded-lg capitalize whitespace-nowrap ${activeTab === tab ? 'bg-accent text-white' : ''}`}
          onClick={(e) => handleTabs(tab)}
        >
          {tab + ' Report'}
        </button>
      ))}
    </div>
  );
}

export default ReportTabs;
