import React, { useState, useContext, useEffect } from 'react';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { AppContext } from '../../appContext';
import { getNotificationAPI } from '../../apis';
import { EditProfileLoader } from '../../components/common/loader';

function NotificationOdds() {
  const { loggedInUser } = useContext(AppContext);
  const [notification, setNotification] = useState(null);
  const [profileData, setProfileData] = useState('');
  const [viewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const getNotification = async () => {
    try {
      const res = await getNotificationAPI();
      if (res.status === 200) {
        setNotification(res.data.AdminNotification);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
    }
  };

  useEffect(() => {
    getNotification()
  }, []);

  function handleViewRequestBlock(data) {
    setViewRequestBlock(!viewRequestBlock);
    setCurrentRequest(data);
  }

  const mapFunctionWithNullCheck = (dataArray, component) => {
    if (!dataArray) return null;
    return dataArray?.map((data, i) => {
      return (
        <MessageCard
          data={data}
          notificationfun={() => { getNotification(); }}
          viewFunction={handleViewRequestBlock}
          setcurrentrequest={setCurrentRequest}
          profileData={profileData}
          key={i}
        />
      );
    });
  };

  const allMsgBlock = mapFunctionWithNullCheck(notification?.allNotification, MessageCard);
  const readMsgBlock = mapFunctionWithNullCheck(notification?.readNotification, MessageCard);
  const unreadMsgBlock = mapFunctionWithNullCheck(notification?.unReadNotification, MessageCard);

  const [tab, setTab] = useState(1);
  const handleTabs = (prop) => {
    setTab(prop);
  };

  return (
    <AdminLayout>
      <div className="p-4 md:px-8 2xl:container 2xl:mx-auto bg-white min-h-screen pb-20 xl:pb-0">
        <div className="flex items-center gap-2 min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
          <HiOutlineArrowSmallLeft
            className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 xl:hidden"
            onClick={() => {
              window.history.back();
            }}
          />
          <p className="headingText text-coalBlack">Notification</p>
        </div>
        <div className="flex items-center gap-6 mt-5 p-4 border-b border-gray-200">
          <p className={`!font-medium baseText cursor-pointer capitalize border-b-[3px]  hover:text-accent  ${tab == 1 ? 'border-accent text-accent' : 'text-navLink border-transparent'}`} onClick={() => { handleTabs(1); }}>
            All
          </p>
          <p className={`!font-medium baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${tab == 2 ? 'border-accent text-accent' : 'text-navLink border-transparent'}`} onClick={() => { handleTabs(2); }}>
            Read
          </p>
          <p className={`!font-medium baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${tab == 3 ? 'border-accent text-accent' : 'text-navLink border-transparent'}`} onClick={() => { handleTabs(3); }}>
            Unread
          </p>
        </div>
        {
          notification ? (
            <>
              {tab === 1 && ( // For tab 1
                <>
                  {notification?.allNotification?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                      {allMsgBlock}
                    </div>
                  ) : (
                    <p className="text-gray-400 text-center w-full p-10">There are no notifications for me to display at the moment.</p>
                  )}
                </>
              )}

              {tab === 2 && ( // For tab 2
                <>
                  {notification?.readNotification?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                      {readMsgBlock}
                    </div>
                  ) : (
                    <p className="text-gray-400 text-center w-full p-10">There are no notifications for me to display at the moment.</p>
                  )}
                </>
              )}

              {tab === 3 && ( // For tab 3
                <>
                  {notification?.unReadNotification?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                      {unreadMsgBlock}
                    </div>
                  ) : (
                    <p className="text-gray-400 text-center w-full p-10">There are no notifications for me to display at the moment.</p>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="w-full flex item-center justify-center mt-5">
              <EditProfileLoader />
            </div>
            
          )
        }


      </div>
      {/* {viewRequestBlock && (
        <ConsultationViewRequest currentdata={currentRequest} profileData={profileData} closeFunction={handleViewRequestBlock} onload={onload} />
      )} */}
    </AdminLayout>
  );
}

export default NotificationOdds;
