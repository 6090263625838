import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { AdminDashboardContext } from '../../appContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyEarningsChart = () => {
  const { dashboardDetails, setDashboardDetails, applyFilter } = useContext(
    AdminDashboardContext
  );
  const data = dashboardDetails?.MonthlyEarning;

  const chartData = {
    labels: data?.map((item) => item?._id.slice(0, 3)),
    datasets: [
      {
        label: 'Monthly Earnings',
        data: data?.map((item) => item?.total_amount),
        backgroundColor: 'rgba(75, 192, 192, 0.6)', 
        borderColor: 'rgba(75, 192, 192, 1)',
        barThickness: 12,
        borderRadius: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 8,
          boxHeight: 8,
          font: {
            family: 'Poppins',
          },
        },
      },
      title: {
        display: true,
        text: 'Monthly Earnings',
        font: {
          family: 'Poppins',
          size: 18,
          weight: 500,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          font: {
            family: 'Poppins',
          },
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          font: {
            family: 'Poppins',
          },
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
    },
  };

  return (
    <div style={{ marginBottom: '14px', minHeight: '250px' }}>
      <Bar data={chartData} options={options} height={300} />
    </div>
  );
};

export default MonthlyEarningsChart;
