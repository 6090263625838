import React, { useState } from 'react';
import ReportTabs from './ReportTab'; // Ensure the correct path
import AdminLayout from '../../components/admin-layout';
import HospitalReport from './HospitalReport';
import DoctorReport from './DoctorReport';
import CreditPurchaseReport from './CreditPurchaseReport';
import PayoutReport from './PayoutReport';
import ConsultationReport from './ConsultationReport';
import SubscriptionHistoryReport from './SubscriptionHistoryReport';
import MonthlyEarnings from './MonthlyEarnings';

function ReportsTab() {
  const [activeTab, setActiveTab] = useState('Doctor');
  const handleTabs = (tab) => {
    setActiveTab(tab);
  };
  function renderTabContent(activeTab) {
    switch (activeTab) {
    case 'Doctor':
      return <DoctorReport />;
    case 'Hospital':
      return <HospitalReport />;
    case 'Credit Purchase':
      return <CreditPurchaseReport />;
    case 'Payout':
      return <PayoutReport />;
    case 'Consultation':
      return <ConsultationReport />;
      // case 'Subscription':
      //   return <SubscriptionHistoryReport />;
    case 'Monthly Earnings':
      return <MonthlyEarnings />;
    default:
      return <div>No Report to Display</div>;
    }
  }

  return (
    <>
      <AdminLayout>
        <section className="xl:p-5 xl:pb-0">
          <section className="bg-white p-4 rounded-sm mt-1 mb-14 xl:mb-0 xl:mt-2 xl:p-6">
            <ReportTabs handleTabs={handleTabs} activeTab={activeTab} />
            <div className="mt-6">{renderTabContent(activeTab)}</div>
          </section>
        </section>
      </AdminLayout>
    </>
  );
}

export default ReportsTab;
