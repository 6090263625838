import React, { useEffect, useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { getSubscriptionPlans } from '../../apis';
import PlanCard from './PlanCard';
import { Loader } from '../common/loader';


function ExistingPlan({ closeFunction }) {
  const [plans, setPlans] = useState(null);
  const [loader, setLoader] = useState(true);
  const [resetPlan, setResetPlan] = useState(true);
  const fetchPlans = async () => {
    try {
      const res = await getSubscriptionPlans();

      if (res.status == 200) {
        setPlans(res?.data?.result);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const handleResetPlan = () => {
    setResetPlan(!resetPlan);
  };

  useEffect(() => {
    fetchPlans();
  }, [resetPlan]);
  return (
    <>
      <div className="bg-bodybackground rounded-2xl text-coalBlack py-5 min-w-[900px] overflow-x-hidden " >
        <header className="flex items-center sticky top-0 z-10 bg-inherit p-8 py-6 border-b border-gray-100 mb-10">
          <h1 className="headingText capitalize">Subscription Plans</h1>
          <button
            className="baseText !font-medium ms-auto text-navLink"
            onClick={() => closeFunction()}
          >
            Discard
          </button>
        </header>
        <div className="flex flex-wrap gap-x-4 gap-y-10 px-8">
          {loader ? (
            <div className="flex justify-center items-center h-72 w-full">
              <Loader />
            </div>
          ) : (
            plans?.map((data, i) => (
              <PlanCard data={data} resetPlan={handleResetPlan} />
            ))
          )}
        </div>
      </div>
      
    </>
  );
}

export default ExistingPlan;
