import React, { useContext, useEffect, useState } from 'react';
import LiveActivityCard from '../../components/liveActivity/LiveActivityCard';
import { AdminDashboardContext } from '../../appContext';
import { liveActivityApi } from '../../apis';

function LiveActivity() {
  const [creditUsageState, setCreditUsageState] = useState(1);
  const [data, setdata] = useState();
  const { dashboardDetails, setDashboardDetails, applyFilter } = useContext(
    AdminDashboardContext
  );

  const fetchLiveActivity = async (payload) => {
    try {
      const res = await liveActivityApi(payload);
      if (res.status === 200) {
        setdata(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchLiveActivity(applyFilter);
  }, [applyFilter]);
  return (
    <section className="creditsBlock">
      {data?.creditpurchase?.map((data, i) =>
        i !== 0 ? (
          <p className="text-coalBlack baseText text-center my-10">
            No results found
          </p>
        ) : (
          <LiveActivityCard data={data} key={i} i={i} />
        )
      )}
    </section>
  );
}

export default LiveActivity;
