import React, { useContext, useEffect, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import { AdminDashboardContext, AppContext } from '../../appContext';
import TotalSale from './TotalSale';
import BookingsCard from './BookingsCard';
import CreditSold from './CreditSold';
import AppointmentDis from './AppointmentDis';
import LiveActivity from './LiveActivity';
import { TopHospital, getDashboardDetails } from '../../apis';
import { MdAirlineStops } from 'react-icons/md';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import { GoCheckCircleFill } from 'react-icons/go';
import { GoXCircleFill } from 'react-icons/go';
import Avatar from 'react-avatar';
import MonthlyEarningsChart from './MonthlyEarningsChart';

function Index() {
  const { userDetails } = useContext(AppContext);
  const [dashboardDetails, setDashboardDetails] = useState();
  const [isLoad, setIsLoad] = useState(true);
  const [applyFilter, setApplyFilter] = useState('?filter=yearly');
  const [topHospital, setTopHospital] = useState([]);
  const fetchDashboardDetails = async (payload) => {
    try {
      const res = await getDashboardDetails(payload);
      if (res.status === 200) {
        setDashboardDetails(res.data.Count);
        setIsLoad(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoad(false);
    }
  };
  const fetchTopHospital = async (payload) => {
    try {
      const res = await TopHospital(payload);
      if (res.status === 200) {
        const array = [];
        res.data?.result.map((data) => {
          console.log(data?.hospitalProfilePhoto[0]);
          console.log(data?.hospitalProfilePhoto[0]!=='');
          const obj = {
            name: (
              <div className="inline-flex gap-2 items-center">
                <span className="size-10 min-w-10 bg-gray-100 rounded-full">
                  {data?.hospitalProfilePhoto.length !== 0 ? (
                    <Avatar name={data?.hospitalName} src={data?.hospitalProfilePhoto[0]} size="40" round={true} textSizeRatio={2} />
                  ) : (
                    null
                  )}
                </span>
                <p className="w-[10ch] overflow-hidden  whitespace-nowrap truncate">
                  {data?.hospitalName}
                </p>
              </div>
            ),
            appointment: <p className="ps-10">{data?.noOfConsultations}</p>,
            sales: data?.PaidAmt,
            active:
              data.Active[0] === true ? (
                <div>
                  <GoCheckCircleFill className="text-green-500 text-xl inline-block me-1" />
                  Active
                </div>
              ) : (
                <div>
                  <GoXCircleFill className="text-red-500 text-xl inline-block me-1" />
                  In-Active
                </div>
              ),
          };
          array.push(obj);
        });
        setTopHospital(array);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchDashboardDetails(applyFilter);
    fetchTopHospital(applyFilter);
  }, [applyFilter]);
  const TableHeaders = ['HOSPITALS', 'APPOINMENTS', 'Paid Amount ', 'status'];
  const handleFilters = (e) => {
    setApplyFilter(e.target.value);
  };
  return (
    <AdminDashboardContext.Provider
      value={{ applyFilter, dashboardDetails, setDashboardDetails }}
    >
      <AdminLayout>
        <div className="hidden xl:flex  p-5 bg-blue-700 rounded-br-2xl rounded-bl-2xl relative bg-hero bg-cover bg-no-repeat  bg-left min-h-36  items-center">
          <div className="flex items-center flex-grow ">
            <h1 className="text-white highlightText  capitalize">
              Welcome, {userDetails?.Profile?.userName}
            </h1>
            <div
              className={
                'py-3  rounded-xl inline-flex items-center bg-white ms-auto '
              }
            >
              <div className="px-4 border-r border-gray-300">
                <div className="inline-flex items-center gap-2">
                  <img src={require('../../Assets/img/odds/Find Clinic.png')} />
                  <p className="headingText text-coalBlack inline-block">
                    {dashboardDetails?.RegisteredHospital}
                  </p>
                </div>
                <p className=" text-navLink subHeadingText capitalize">
                  Partner Hospitals
                </p>
              </div>
              <div className="px-4 ">
                <div className="inline-flex items-center gap-2">
                  <img
                    src={require('../../Assets/img/odds/Medical Doctor.png')}
                  />
                  <p className="headingText  text-coalBlack inline-block leading-[0px]">
                    {dashboardDetails?.RegisteredDoctors}
                  </p>
                </div>
                <p className="subHeadingText text-navLink  capitalize">
                  Registered Doctors
                </p>
              </div>
            </div>
          </div>
        </div>
        {isLoad ? null : (
          <main className="mt-10 p-5">
            <section className="grid grid-cols-12 gap-5">
              <div className="p-4 rounded-2xl col-span-8">
                <TotalSale />
                <div className="grid grid-cols-2 gap-5 mt-10">
                  <div className="bg-white p-4 pb-0 rounded-2xl ">
                    <MonthlyEarningsChart />
                  </div>
                  <div className="bg-white p-4 pb-0 rounded-2xl">
                    <AppointmentDis />
                  </div>
                </div>
                <section className="mt-10">
                  <div className="bg-white rounded-xl p-5">
                    <div className="flex justify-between items-center">
                      <p className="capitalize subHeadingText text-coalBlack">
                        Top hospitals
                      </p>
                      <div className="p-1 rounded-full bg-green-200">
                        <MdAirlineStops className="text-xl text-green-600" />
                      </div>
                    </div>
                    <div className="mt-4">
                      <PaginatedTable
                        itemsPerPage={15}
                        items={topHospital}
                        tableHeaders={TableHeaders}
                        
                      />
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-span-4 space-y-3 h-fit sticky top-0 pt-4">
                <div>
                  <select
                    className="bg-accent text-white px-4 py-2 rounded-[4px] min-w-40 w-full"
                    onChange={handleFilters}
                  >
                    <option
                      className="text-coalBlack bg-white  whitespace-nowrap"
                      value={'?filter=yearly'}
                    >
                      Yearly
                    </option>
                    <option
                      className="text-coalBlack bg-white  whitespace-nowrap"
                      value={'?filter=monthly'}
                    >
                      Monthly
                    </option>
                    <option
                      className="text-coalBlack bg-white  whitespace-nowrap"
                      value={'?filter=weekly'}
                    >
                      Weekly
                    </option>
                  </select>
                </div>
                <div className="">
                  <BookingsCard
                    imgUrl={require('../../Assets/img/odds/calender.png')}
                    bookingTitle={'In-Person Appointments'}
                    bookingCount={dashboardDetails?.InPersonAppointments}
                    bookingPercentage={'13.18%'}
                    bookingFromDate={'from last week'}
                  />
                </div>
                <div className="">
                  <BookingsCard
                    imgUrl={require('../../Assets/img/odds/virtualbookings.png')}
                    bookingTitle={'Virtual Appointments'}
                    bookingCount={dashboardDetails?.VirtualAppointments}
                    bookingPercentage={'10.8%'}
                    bookingFromDate={'from last week'}
                  />
                </div>
              </div>
            </section>
            {/* <section className="grid grid-cols-12 gap-5 mt-10">
              <div className=" col-span-8">
               
              </div>
             
            </section> */}
          </main>
        )}
      </AdminLayout>
    </AdminDashboardContext.Provider>
  );
}

export default Index;
