import React, { useEffect, useState } from 'react';
import CreditUpdateCard from '../../components/creditManagement/CreditUpdateCard';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import MainOddsPopup from '../../components/Modals/MainOddsPopup';
import { useFormik } from 'formik';
import {
  creditUpdateValidation,
  payoutUpdateValidation,
} from '../../components/Validation';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  getCreditBasePrice,
  getSpecialization,
  updateCreditDetails,
} from '../../apis';
import toast from 'react-hot-toast';
import ConfirmPopup from '../../components/confirmPopup/ConfirmPopup';
function UpdateCredits({ closeFunction }) {
  const [creditTab, setCreditTab] = useState(1);
  const [creditDetails, setCreditDetails] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [specializationData, setSpecializationData] = useState(null);

  const validateForm = async (data) => {
    try {
      await creditUpdateValidation.validate(data, { abortEarly: false });
      console.log('Form is valid');
      setConfirmPopup(!confirmPopup);
    } catch (err) {
      console.log('Form is invalid');
      formik.setFieldTouched('virtualCreditUpdate', true);
      formik.setFieldTouched('inPersonCreditUpdate', true);
    }
  };

  const handleConfirmPopup = async () => {
    const value = {
      inPersonCreditUpdate: formik.values.virtualCreditUpdate,
      virtualCreditUpdate: formik.values.inPersonCreditUpdate,
    };
    await validateForm(value);
  };
  const formik = useFormik({
    initialValues: {
      inPersonCreditUpdate: creditDetails?.InpersonConsultationCost || 0,
      virtualCreditUpdate: creditDetails?.VirtualConsultationCost || 0,
    },
    validationSchema: creditUpdateValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values);
      const { inPersonCreditUpdate, virtualCreditUpdate } = values;
      console.log(inPersonCreditUpdate);
      // const payload = {
      //   Id: creditDetails?._id,
      //   creditsPerVirtualConsultation: inPersonCreditUpdate,
      //   creditsPerInpersonConsultation: virtualCreditUpdate,
      // };
      const payload = {
        Id: creditDetails?._id,
        InpersonConsultationCost: inPersonCreditUpdate,
        VirtualConsultationCost: virtualCreditUpdate,
      };
      toUpdateCreditDetails(payload);
    },
  });

  const formik2 = useFormik({
    initialValues: { inPersonPayoutUpdate: 4688, virtualPayoutUpdate: 2563 },
    validationSchema: payoutUpdateValidation,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const virtualUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              value={formik.values.virtualCreditUpdate}
              onChange={formik.handleChange}
              placeholder={'Enter amount'}
              name="virtualCreditUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik.touched.virtualCreditUpdate &&
            formik.errors.virtualCreditUpdate
          }
        />
      </p>
    </>
  );
  const inPersonUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              name="inPersonCreditUpdate"
              value={formik.values.inPersonCreditUpdate}
              onChange={formik.handleChange}
              placeholder={'Enter amount'}
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik.touched.inPersonCreditUpdate &&
            formik.errors.inPersonCreditUpdate
          }
        />
      </p>
    </>
  );

  const virtualPayoutUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              value={formik2.values.virtualPayoutUpdate}
              onChange={formik2.handleChange}
              name="virtualPayoutUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik2.touched.virtualPayoutUpdate &&
            formik2.errors.virtualPayoutUpdate
          }
        />
      </p>
    </>
  );
  const inPersonPayoutUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              value={formik2.values.inPersonPayoutUpdate}
              onChange={formik2.handleChange}
              name="inPersonPayoutUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik2.touched.inPersonPayoutUpdate &&
            formik2.errors.inPersonPayoutUpdate
          }
        />
      </p>
    </>
  );
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const toUpdateCreditDetails = async (payload) => {
    try {
      const res = await updateCreditDetails(payload);
      if (res.status === 200) {
        console.log(res);
        toast.success('Amount Updated Successfully');
        setConfirmPopup(!confirmPopup);
        closeFunction();
      }
    } catch (error) {
      console.log(error);
      setConfirmPopup(!confirmPopup);
      toast.error(error.statusText || 'Something went wrong');
    } finally {
      setConfirmPopup(!confirmPopup);
      closeFunction();
    }
  };
  const toGetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        console.log(res?.data?.result);
        setSpecializationData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    toGetCreditBasePrice();
    toGetSpecialization();
  }, []);
  return (
    <MainOddsPopup closeFunction={closeFunction}>
      <div className="w-full p-8 bg-bodybackground text-coalBlack min-w-[880px]">
        {/* Navigation  */}
        <div className="flex items-center">
          <div className="inline-flex gap-6 items-center">
            <SimpleButton
              title={'Consultation Cost'}
              buttonType={creditTab === 1 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(1);
              }}
            />
            {/* <SimpleButton
              title={'Consultation Payout'}
              buttonType={creditTab === 2 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(2);
              }}
            /> */}
            <SimpleButton
              title={'Specialization Cost'}
              buttonType={creditTab === 3 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(3);
              }}
            />
          </div>
          <p
            className="subHeadingText text-navLink ms-auto cursor-pointer"
            onClick={closeFunction}
          >
            Discard
          </p>
        </div>
        <h1 className="headingText my-11">
          {creditTab === 1
            ? 'Consultation Cost'
            : creditTab === 3
              ? 'Specialization Cost'
              : ''}
        </h1>
        {creditTab === 1 ? (
          <section className=" flex items-center gap-11">
            <CreditUpdateCard
              updateField={inPersonUpdateBlock}
              conType={' In-Person Consultation'}
              message={
                'This amount will be charged for hospitals to book in-person doctor visit.'
              }
            />
            <CreditUpdateCard
              updateField={virtualUpdateBlock}
              conType={'Virtual Consultation'}
              message={
                'This amount will be charged for hospitals to book in-person doctor visit.'
              }
            />
          </section>
        ) : creditTab === 2 ? (
          <section className=" flex items-center gap-11">
            <CreditUpdateCard
              updateField={inPersonPayoutUpdateBlock}
              conType={' In-Person Payout'}
              message={
                'The amount which will be paid to doctors for in-person consultation. '
              }
            />
            <CreditUpdateCard
              updateField={virtualPayoutUpdateBlock}
              conType={'Virtual Payout'}
              message={
                'The amount which will be paid to doctors for in-person consultation. '
              }
            />
          </section>
        ) : creditTab === 3 ? (
          <section className="w-full bg-white rounded-xl p-3 ">
            <p className="baseText  mb-3 text-navLink text-center">
              This amount will be charged for hospitals to book consultations
              with these specializations.
            </p>
            <div className="grid grid-cols-2 gap-x-10 mb-3 gap-y-4 max-w-fit mx-auto">
              {specializationData?.map((data) => (
                <>
                  <p className="subHeadingText text-coalBlack capitalize">
                    {data?.name}
                  </p>
                  <p className="subHeadingText text-coalBlack capitalize text-center">
                    {data?.Amount} ₹
                  </p>
                </>
              ))}
            </div>
          </section>
        ) : null}
        {creditTab != 3 ? (
          <div className="mt-11">
            <SimpleButton
              title={'Update'}
              buttonType={'primary'}
              customClass={'rounded-2xl ms-auto '}
              onClickEvent={creditTab === 1 ? handleConfirmPopup : null}
            />
          </div>
        ) : null}
      </div>
      {confirmPopup && (
        <ConfirmPopup
          message={'Are you sure you want to update the Amount?'}
          popupStatus={confirmPopup}
          onclickFunction={
            creditTab === 1 ? formik.handleSubmit : formik2.handleSubmit
          }
          handelConfirmPopUp={handleConfirmPopup}
        />
      )}
    </MainOddsPopup>
  );
}

export default UpdateCredits;
