import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';
import { FaArrowRight } from 'react-icons/fa';
import MainOddsPopup from '../Modals/MainOddsPopup';
import ViewNewRequest from '../HospitalManagementViews/HospitalViewNewRequest';
import ViewInformation from '../HospitalManagementViews/HospitalViewInformation';
import ViewDetails from '../ConsultationCard/ViewDetails';

function TableListCredit({ hit, columnWidth }) {
  console.log(hit);
  const [viewRequest, setViewRequest] = useState(false);
  const [currentrequest, setcurrentrequest] = useState(null);
  const [profileData, setprofileData] = useState('');
  const handleViewNewrequest = (prop) => {
    setcurrentrequest(prop);
    setViewRequest(!viewRequest);
  };
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            <p className="char10">{hit?.HospitalName}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            <p className="char10">{hit?.DoctorName?.[0]}</p>
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            {hit?.Specalaization?.[0]}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            {hit?.ConsultationType == 'inperson' ? 'In-person' : 'Virtual'}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText ps-4"
          >
            {hit?.ConsultationCharge[0]}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            <Moment format="DD/MM/YYYY" date={hit?.RequestedDate} />
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText capitalize"
          >
            {hit?.Status == 'completed' ? (
              <p className="min-w-20 text-center capitalize smallText text-green-600 bg-green-200 rounded-lg p-1 px-2 inline-block">
                closed
              </p>
            ) : hit?.Status == 'no-show' ? (
              <p className="min-w-20 text-center capitalize smallText text-red-600 bg-red-200 rounded-lg p-1 px-2 inline-block">
                No-Show
              </p>
            ) : (
              <p className="min-w-20 text-center capitalize smallText text-blue-600 bg-blue-200 rounded-lg p-1 px-2 inline-block">
                open
              </p>
            )}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText"
          >
            <a
              className="text-coalBlack"
              onClick={() => handleViewNewrequest(hit?._id)}
            >
              View <FaArrowRight className="inline-block ms-1" />
            </a>
          </div>
        </div>
      </div>
      {viewRequest && (
        <ViewDetails
          closeFunction={handleViewNewrequest}
          currentdata={currentrequest}
          profileData={profileData}
        />
      )}
    </div>
  );
}

export default TableListCredit;
