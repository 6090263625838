import React, { useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import { updateSubscriptionPlan } from '../../apis';
import toast from 'react-hot-toast';
import ExistingPlanDetails from './ExistingPlanDetails';
import MainOddsPopup from '../Modals/MainOddsPopup';
function PlanCard({ data, resetPlan }) {
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [existingPlanDetails, setExistingPlanDetails] = useState(false);

  const handleConfirmPopup = (id) => {
    setPlanId(id);
    setConfirmPopup(!confirmPopup);
  };
  const onUpdatePlan = async () => {
    try {
      const res = await updateSubscriptionPlan(planId);
      if (res.status === 200) {
        console.log(res);
        toast.success(
          <span className="whitespace-nowrap">{res?.data?.message}</span> ||
            'Plan deleted successfully'
        );
        handleConfirmPopup();
        resetPlan();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleExistingPlanDetails = () => {
    setExistingPlanDetails(!existingPlanDetails);
  };
  return (
    <div
      className="bg-white rounded-2xl shadow-blue_dropshadow2 p-4 w-full max-w-[350px] flex flex-col" 
      key={data?._id}
    >
      <div className="flex items-center">
        <div className="size-16 rounded-full bg-white shadow-lg flex justify-center items-center">
          {data.planIcon != '' && (
            <img
              src={data?.planIcon}
              className="rounded-full block object-cover size-12"
            />
          )}
        </div>
        <div className='ms-auto'>
          <button
            className="bg-accent text-white baseText min-w-20 py-2 rounded-full  "
            onClick={handleExistingPlanDetails}
          >
          View
          </button>
          <button
            className="bg-red-500 text-white baseText min-w-20 ms-3 py-2 rounded-full  "
            onClick={() => handleConfirmPopup(data?._id)}
          >
          Delete
          </button>
        </div>
      </div>
      <div className="mt-6 flex flex-col grow">
        <p className="subHeadingText  text-coalBlack capitalize">
          {data?.planName}{' '}
        </p>
        <p className="baseText text-navLink grow mb-2">
          {data?.planDescription}
        </p>
        <div className="p-3 px-5 rounded-2xl shadow-blue_dropshadow2 inline-flex items-center gap-x-4 divide-x-2 mt-auto w-fit ">
          <p className="baseText text-coalBlack">
            ₹ {data?.planAmount} / <span className="capitalize">{data?.period}</span>
          </p>
          
        </div>
      </div>
      {confirmPopup && (
        <ConfirmPopup
          fillBtnTitle={'yes'}
          unFillBtnTitle={'No'}
          message={'Are you sure you want to delete this plan?'}
          popupStatus={confirmPopup}
          handelConfirmPopUp={handleConfirmPopup}
          onclickFunction={onUpdatePlan}
        />
      )}
      {existingPlanDetails && (
        <MainOddsPopup closeFunction={handleExistingPlanDetails}>
          <ExistingPlanDetails closeFunction={handleExistingPlanDetails} data={data} />
        </MainOddsPopup>
      )}
    </div>
  );
}

export default PlanCard;
