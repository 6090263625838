import React from 'react';

function ResponsiveTable({ headers, data }) {
  return (
    <>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 scrollbar">
        <thead className="smallText text-navLink uppercase ">
          <tr className="bg-gray-50 ">
            {headers?.map((header, index) => (
              <th key={index} scope="col" className="px-6 py-3 font-semibold whitespace-nowrap">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <tr className='h-40'>
              <td colSpan={headers.length} className='text-center baseText'>There were no results.</td>
            </tr>
          ) : (
            data?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="bg-white text-coalBlack smallText !font-medium  hover:bg-blue-50/50"
              >
                {Object.values(row)?.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={`px-6 py-4 ${
                      cellIndex == 0 ? 'capitalize' : ''
                    }`}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
}

export default ResponsiveTable;
