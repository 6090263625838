import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import '../tableList.css';
import Moment from 'react-moment';

function CommisionHistoryTable({ hit, columnWidth }) {
  return (
    <div>
      <div className="table-list-body">
        <div className="table-list-body-item">
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText cursor-pointer char15 pe-5"
          >
            {hit?.hospitalName}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText line-clamp-1"
          >
            <Moment format="MMMM" date={hit?.createdAt} />
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText line-clamp-1"
          >
            <Moment format="DD/MM/YYYY" date={hit?.createdAt} />
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText line-clamp-1 text-center"
          >
            {hit?.noofconsultation}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText text-center "
          >
            ₹ {hit?.totalAmtpaid}
          </div>
          <div
            style={{ width: columnWidth }}
            className="text-coalBlack smallText text-center"
          >
            ₹ {hit?.commissionamt}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommisionHistoryTable;
