import React,{useEffect, useState,useContext} from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { AppContext } from '../../appContext';
import Noprofile from '../../Assets/no-image.png';
import { getPayoutById } from '../../apis';
function ViewPayoutDetails({ id,closeFunction }) {

  const[Loader,setLoader]=useState(false);
  const[data,setdata]=useState(null);
  const getItem=async()=>{
    try {
      setLoader(true);
      const res = await getPayoutById(id);
      if (res.status === 200) {
        console.log(res.data.result);
        setdata(res.data.result);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    }
  };
  useEffect(()=>{
    if(id){
      getItem();
    }
    
  },[]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = String(hours).padStart(2, '0'); // pad with leading zero if necessary

    return `${year}/${month}/${day} ${hours}:${minutes} ${ampm}`;
  };
  return (
    <div className="bg-white  rounded-2xl text-coalBlack py-5">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 border-b border-gray-100 mb-10">
        <h1 className="headingText capitalize">Doctor information</h1>
        <button
          className="capitalize text-base font-medium text-navLink ms-auto"
          onClick={() => {
            closeFunction();
          }}
        >
          close
        </button>
      </header>
      <main className="space-y-10 px-4">
        <section className=" flex flex-row gap-6 shadow-blue_dropshadow2 p-4 rounded-[58px]">
          <div className="max-w-[290px]">
            {
              data?.doctorId?.coverPic ? 
                <img src={data?.doctorId?.coverPic} className="h-[230px] w-[290px] rounded-[42px]  bg-slate-200 object-cover" /> : 
                <img src={Noprofile} className="h-[230px] w-[290px] rounded-[42px]  bg-slate-200" />
            }
          </div>
          <div className="w-full space-y-10">
            <div className="block">
              <div className="grid grid-cols-2 xl:grid-cols-3 py-3  gap-x-4 gap-y-6 xl:gap-y-10">
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText "> Name</p>
                  <p className="baseText capitalize text-coalBlack">
                    {data?.doctorId?.DoctorName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText  line-clamp-1">
                    phone number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.doctorId?.countryCode} {data?.doctorId?.phoneNo}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText  line-clamp-1">
                    Account Number
                  </p>
                  <p className="baseText break-all  text-coalBlack">
                  {data?.bankAccountId?.accountNumber}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText  line-clamp-1">
                    IFSC code
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.bankAccountId?.ifscCode}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText  line-clamp-1">
                    Registration Id
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.doctorId?.regNumber}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-navyBlue capitalize subHeadingText  line-clamp-1">
                    Transaction ID
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.TransactionId}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mx-5 shadow-blue_dropshadow2 p-6 rounded-[34px] text-coalBlack">
          <h1 className="headingText capitalize">Payment detail</h1>
          <div className="mt-10 grid grid-cols-3 gap-4">
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="subHeadingText capitalize">Paid Amount</p>
              </div>
              <div className="baseText py-5">{data?.withdrawAmt} ₹</div>
            </div>
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="subHeadingText capitalize">Payment Method</p>
              </div>
              <div className="baseText py-5">{data?.TransactionMethod} Transaction</div>
            </div>
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="subHeadingText capitalize">Paid Date</p>
              </div>
              <div className="baseText py-5">{formatDate(data?.paidDate)}</div>
            </div>
          </div>
        </section>
        <section className="mx-5">
          <div className="flex items-center px-4 mb-8">
            <p className="baseText !font-medium text-navLink">
              <AiFillInfoCircle className="inline-block text-accent me-1 text-xl" />
              Payment completed for this request
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ViewPayoutDetails;
