import React from 'react';

function LiveActivityCard({ data, i }) {
  return (
    <div className="flex  gap-4 p-2 py-3  rounded-lg">
      <span
        className={`block size-4 rounded-full border-2 border-accent mt-1 relative   after:bg-navLink after:w-px  after:absolute after:top-full after:content-[''] after:left-1/2 after:-translate-x-1/2 ${
          i === 0 ? 'after:h-[0%]' : 'after:h-[610%]'
        }`}
      ></span>
      <div>
        <p className="baseText text-coalBlack capitalize line-clamp-1">
          {data?.hospitalName}
        </p>
        <p className="baseText text-coalBlack capitalize">
          {data?.noOfCredits} XP, credits Purchase
          {/* 100 XP, credits Purchase */}
        </p>
        <p className="smallText text-navLink">
          {data?.createdAt.split('T')[0]}
        </p>
        {/* <p className="smallText text-navLink">24/05/2024</p> */}
      </div>
    </div>
  );
}

export default LiveActivityCard;
