import React, { useContext, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { AdminDashboardContext } from '../../appContext';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const DoughnutChart = () => {
  const { dashboardDetails, setDashboardDetails, applyFilter } = useContext(
    AdminDashboardContext
  );
  let pCred = dashboardDetails?.NoOfcreditPurchased[0]?.TotalNoOfCreditPurchased;
  let uCred = dashboardDetails?.NoOfcreditUsed[0]?.TotalNoOfCreditUsed;

  console.log(dashboardDetails?.NoOfcreditPurchased);
  console.log(dashboardDetails?.NoOfcreditUsed);
  console.log(dashboardDetails?.NoOfcreditPurchased[0]?.TotalNoOfCreditPurchased);
  console.log(dashboardDetails?.NoOfcreditUsed[0]?.TotalNoOfCreditUsed);
  // TotalNoOfCreditsPurchased

  const [month, setMonth] = useState('January');

  const data = {
    labels: ['Credits Purchased', 'Credits Used'],
    datasets: [
      {
        label: 'Credits',
        data: [pCred, uCred],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            family: 'Poppins',
          },
        },
      },
      title: {
        display: true,
        text: 'Credit Purchase History',
        font: {
          family: 'Poppins',
          size: 18,
          weight:500
        },
      },
      tooltip: {
        titleFont: {
          family: 'Poppins',
        },
        bodyFont: {
          family: 'Poppins',
        },
        footerFont: {
          family: 'Poppins',
        },
      },
    },
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  return (
    <div>
      <div style={{ height: '300px', marginBottom: '14px' }}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default DoughnutChart;
