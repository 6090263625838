import Select from 'react-select';
import React, { useEffect, useState,useContext } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { MarkAsPaid, getHospitalById, getPayoutById } from '../../apis';
import toast from 'react-hot-toast';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../common/loader';
import Noprofile from '../../Assets/no-image.png';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../errorMessages';
import { MarkAsPaidValidation, creditUpdateValidation } from '../Validation';
function ViewPayoutRequest({ id,closeFunction }) {
  const[Loader,setLoader]=useState(false);
const[data,setdata]=useState(null);
  const getItem=async()=>{
    try {
      setLoader(true);
      const res = await getPayoutById(id);
      if (res.status === 200) {
        console.log(res.data.result)
        setdata(res.data.result)
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error)
      //toast.error(message, { id: 'error' });
    }
  }
  useEffect(()=>{
    if(id){
      getItem();
    }
    
  },[])
  
  const { userDetails } = useContext(AppContext);
 
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = String(hours).padStart(2, '0'); // pad with leading zero if necessary

    return `${year}/${month}/${day} ${hours}:${minutes} ${ampm}`;
};
 const [markasloader,setmarkasloader]=useState(false);
const formik = useFormik({
  initialValues: {
    transactiontype:'',
    transactionid:''
   
  },
   validationSchema: MarkAsPaidValidation,
  onSubmit: async (values) => {
   const {transactionid,transactiontype}=values;
      try {
        setmarkasloader(true);
        const res = await MarkAsPaid({
            "TransactionMethod": transactiontype,
            "TransactionId": transactionid,
            "id":id
        
        });
        if (res.status === 200) {
          toast.success(res.data.message);
         location.reload()
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
        const message =
          error?.response?.data?.message || error?.response?.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        setmarkasloader(false);
      }
    }
  
});
const transactiondata = [
  { label: 'UPI', value: 'UPI' },
  { label: 'Bank transfer', value: 'Bank transfer' }
];
const [transactiontype, settransactiontype] = useState(
  transactiondata.find(option => option.value === formik.values.transactiontype) || null
);

const handleOption = (option) => {
  settransactiontype(option);
  formik.setFieldValue('transactiontype', option.value);
};
  return (
    <div className="bg-white  rounded-2xl text-coalBlack py-5">
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 border-b border-gray-100 mb-10">
        <h1 className="text-2xl font-medium capitalize">Doctor information</h1>
        <button
          className="capitalize text-base font-medium text-navLink ms-auto"
          onClick={() => {
            closeFunction();
          }}
        >
          close
        </button>
      </header>
      <main className="space-y-10 px-4">
        <section className=" flex flex-row gap-6 shadow-blue_dropshadow2 p-4 rounded-[58px]">
          <div className="max-w-[290px]">
          {
              data?.doctorId?.coverPic ? 
              <img src={data?.doctorId?.coverPic} className="h-[230px] w-[290px] rounded-[42px]  bg-slate-200" /> : 
              <img src={Noprofile} className="h-[230px] w-[290px] rounded-[42px]  bg-slate-200" />
            }
           
          </div>
          <div className="w-full space-y-10">
            <div className="block">
              <div className="grid grid-cols-2 xl:grid-cols-3 py-3  gap-x-0 gap-y-6 xl:gap-y-10">
                <div className="singleField">
                  <p className="text-lg text-navyBlue font-semibold"> Name</p>
                  <p className="text-lg font-normal capitalize text-coalBlack/75">
                    {data?.doctorId?.DoctorName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-lg text-navyBlue font-semibold line-clamp-1">
                    phone number
                  </p>
                  <p className="text-lg font-normal capitalize line-clamp-1 text-coalBlack/75">
                  {data?.doctorId?.countryCode} {data?.doctorId?.phoneNo}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-lg text-navyBlue font-semibold line-clamp-1">
                    Account Number
                  </p>
                  <p className="text-lg font-normal capitalize  text-coalBlack/75">
                   {data?.bankAccountId?.accountNumber}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-lg text-navyBlue font-semibold line-clamp-1">
                    IFSC code
                  </p>
                  <p className="text-lg font-normal capitalize line-clamp-1 text-coalBlack/75">
                    {data?.bankAccountId?.ifscCode}
                  </p>
                </div>
                <div className="singleField">
                  <p className="text-lg text-navyBlue font-semibold line-clamp-1">
                    Registration Id
                  </p>
                  <p className="text-lg font-normal capitalize line-clamp-1 text-coalBlack/75">
                    {data?.doctorId?.regNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <form onSubmit={formik.handleSubmit}>
        <section className="mx-5 shadow-blue_dropshadow2 p-6 rounded-[34px] text-coalBlack">
          <h1 className="text-2xl font-medium capitalize">Payment amount</h1>
          <div className="mt-10 grid grid-cols-3 gap-4">
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="text-lg font-medium capitalize">Wallet Balance</p>
              </div>
              <div className="text-xl font-medium py-5">{data?.currentSavingAmt} ₹</div>
            </div>
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="text-lg font-medium capitalize">
                  Requested Amount
                </p>
              </div>
              <div className="text-xl font-medium py-5">{data?.withdrawAmt} ₹</div>
            </div>
            <div className="block border border-gray-200 rounded-xl px-4">
              <div className="flex gap-4 items-center py-5 border-b border-gray-200">
                <div className="rounded-full">
                  <img
                    src={require('../../images/direct-up.png')}
                    className="object-contain size-8 border border-neutral-200 p-1 rounded-full"
                  />
                </div>
                <p className="text-lg font-medium capitalize">Requested Date</p>
              </div>
              <div className="text-xl font-medium py-5">{formatDate(data?.createdAt)}</div>
            </div>
            <div className="block ">
              <p className="text-base font-medium capitalize mb-2">
                Payment Method
              </p>
              <Select
      placeholder="Select payment type"
      classNamePrefix="commenSelectStyle"
      options={transactiondata}
      value={transactiontype}
      onChange={handleOption}
    />
     <InputErrorMessage  error={
                    formik.touched.transactiontype &&
                    formik.errors.transactiontype
                  } />
            </div>
            <div className="block ">
              <p className="text-base font-medium capitalize mb-2">
                Transaction ID
              </p>
              <div className="relative">
                <input
                  type='text'
                  id='transactionid'
                  name='transactionid'
                  className="form-control "
                  placeholder="Enter transaction ID"
                  value={formik.values.transactionid}
                  onChange={formik.handleChange}
                />
                <InputErrorMessage  error={
                    formik.touched.transactionid &&
                    formik.errors.transactionid
                  } />
              </div>
            </div>
          </div>
        </section>
       
        <section className="mx-5">
          <div className="flex items-center px-4 mb-8">
            <p className="text-base font-medium text-navLink">
              <AiFillInfoCircle className="inline-block text-accent me-1 text-xl" />
              Complete payout once manual transaction is completed
            </p>
            <div className="ms-auto">
             <button type='submit' className={`simple-button primary rounded-xl !px-20`} onSubmit={formik.handleSubmit} >
              {markasloader ? <EditProfileLoader /> : 'Mark as paid' } 
              </button>
            </div>
          </div>
        </section>
        </form>
      </main>
    </div>
  );
}

export default ViewPayoutRequest;
